import { FuncLoader } from "../FuncLoader"
import { Loader } from "../Loader"

declare module "../Loader" {
    interface Loader<Value, Params extends any[]> {
        mapError(map: (error: any) => Value | PromiseLike<Value>): Loader<Value, Params>
    }
}

Loader.prototype.mapError = function (map) {
    return new FuncLoader(async (...args) => {
        try {
            return await this.load(...args)
        } catch (e: any) {
            return map(e)
        }
    })
}