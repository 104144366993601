<template>
    <div class="list" v-lazy-root>
        <div class="empty" v-if="activityList.value === undefined || activityList.value.length <= 0">
            <div>
                <img src="./assets/empty.svg" alt="">
                <p>暂无活动</p>
            </div>
        </div>

        <div class="cell" v-for="(item,index) in activityList.value" :key="item.id" >
            <RoundCard>
                <RoundCardHeaderTag :tag="api.ActivityType[item.type]"></RoundCardHeaderTag>
                <div style="position: relative;top: -10px">
                    <div class="content">
                        <f7-photo-browser
                            :photos="[item.image]"
                            ref="f7Browsers"
                            :routable-modals="false"
                        ></f7-photo-browser>
                        <img :src="item.image" alt=""  @click="showImg(index)"/>
                        <f7Link class="content-item" :href="`/offers/detail/${item.id}`">
                            <h4>{{item.title}}</h4>
                            <p>活动时间：<span>{{item.time}}</span></p>
                            <p>分期期数：<span>{{item.num}}</span></p>
                        </f7Link>
                    </div>
                </div>
            </RoundCard>
        </div>
        <div class="flex justify-center py-4" v-if="activityList.hasMore" key="bottom-loader" v-appear="() => activityList.loadMore()">
            <f7Preloader size="20"></f7Preloader>
        </div>
    </div>
</template>

<script setup lang="ts">
import { f7Button, f7Preloader, f7Link , f7PhotoBrowser } from 'framework7-vue';
import RoundCard from 'src/components/RoundControls/RoundCard.vue';
import RoundCardHeaderTag from 'src/components/RoundControls/RoundCardHeaderTag.vue';
import { makePagedLoader } from 'src/Loader';
import { computed, reactive, ref } from 'vue';
import * as api from '../api';

const type = ref<api.ActivityType>()

const activityList = reactive(makePagedLoader(api.getActivityList))

function reloadActivityList() {
    return activityList.withIndicator().load({})
}

const f7Browsers = ref<HTMLElement[]>([])

const showImg =(index:number)=>{
    console.log(f7Browsers.value);
    f7Browsers.value && f7Browsers.value[index]?.open()
}

defineExpose({
    load: reloadActivityList,
    total: computed(() => activityList.total)
})
</script>

<style scoped>
.list {
    overflow-y: auto;
    padding-bottom: 16px;
}

.cell {
    display: block;
    margin-left: 16px;
    margin-right: 16px;
}

.cell+.cell {
    margin-top: 16px;
}
.empty {
    display: flex;
    justify-content: center;
    padding-top: 40px;
}
.empty p {
    text-align: center;
    color: #989898;
    font-size: 16px;
    margin: 0;
}
.content {
    display: inline-flex;
    justify-content: space-between;
}
.content-item{
    display: block;
}
.content-item p {
    font-size: 11px;
    color: #636363;
    line-height: 24px;
}
.content-item h4 {
    font-size: 16px;
    color: #222222;
    font-weight: 400;
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.content-item span {
    font-size: 12px;
    color: #222222;
    font-weight: 400;
    background: #F3F3F3;
    border-radius: 15px;
    padding: 4px 8px;
}

.content img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 10px;
    margin-left: 10px;
}


</style>
