import { resultData,http } from "src/http";

export function h5IndexPage() {
    return resultData.post<AppIndexBox>('/jdbt/jdbtH5Merchant/indexPage');
}

export function registerSubmit(param: string){
    return http.resultData.post('/jdbt/jdbtH5Merchant/registerSubmit',param)
}

export function queryRegisterState(){
    return resultData.post('/jdbt/jdbtH5Merchant/queryRegisterState')
}

export function getBusinessDetail(){
    return resultData.post('/jdbt/jdbtH5Merchant/getRefuseDetail')
}

export function queryAccountName(accountType: number){
    return http.result.post('/jdbt/jdbtH5Merchant/queryAccountName',{accountType})
}

export function getBankList(bankName?: string) {
    return http.resultData.post<BankItem[]>(`/jdbt/jdbtH5Merchant/getOpenBankList`, { bankName }).then((data) => {
        return data;
    });
}

export function getBankBranchList(params?: any) {
    return http.resultData.post<BranchBankItem[]>(`/jdbt/jdbtH5Merchant/getBankBranchList`, params).then((data) => {
        return data;
    });
}

export type BranchBankItem = {
    bankBranchName: string
    cnapBankCode: string
    bankCnaps: string
}

export type BankItem = {
    bankName: string
    bankEnglishNo: string
    cnapBankCode: string
}


export type AppIndexBox = {
    "title": string,
    "content": string,
    "leftBtn": string,
    "rightBtn": string,
    "btnTop": string,
    "btnDown": string,
    "topType": number,
    "downType": number,
    "url": string,
    "iosUrl": string,
    "bottomStr": string,
    "show": Boolean,
    "btnStr": string,
    "type": number
}
