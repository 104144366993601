import { FuncLoader } from "../FuncLoader";
import { Loader } from "../Loader";

export type LoadingIndicator = {
	begin(): void
	end(): void
}

declare module "../Loader" {
    interface Loader<Value, Params extends any[]> {
        withIndicator(indicator?: LoadingIndicator): Loader<Value, Params>
    }
}

Loader.prototype.withIndicator = function (indicator = globalLoadingIndicator) {
	return new FuncLoader(async (...args) => {
		indicator.begin()
		try {
			return await this.load(...args)
		} finally {
			indicator.end()
		}
	})
}

const globalLoadingIndicator: LoadingIndicator = (() => {
	let loadingCount = 0
	let timer: number | undefined
	return {
		begin() {
			if (loadingCount <= 0) {
				timer = window.setTimeout(() => {
					if (loadingCount > 0) {
						window.f7.preloader.show()
					}
				},500)
			}
			loadingCount++
		},
		end() {
			if (loadingCount === 1) {
				window.clearTimeout(timer)
				window.f7.preloader.hide()
			}
			loadingCount--
		}
	}
})()