import { Router } from "framework7/types"
import Index from './index.vue'
const Step1 = () => import('./步骤/Step1.vue')
const Step2 = () => import('./步骤/Step2.vue')
const StoreNew = () => import('./步骤/StoreNew.vue')
const home = () => import('../Home/index.vue')
const routes: Router.RouteParameters[] = [
    {
        path: '/creditCard',
        component: Index
    },
    {
        name:'step1XYK',
        path: '/creditCard/step1',
        asyncComponent: Step1
    },
    {
        path: '/creditCard/step2',
        asyncComponent: Step2
    },
    {
        path: '/creditCard/StoreNew',
        asyncComponent: StoreNew
    },
    {
        path: '/home',
        asyncComponent: home
    },
]

export default routes
