<template>
    <f7Views tabs class="safe-areas">
        <f7Toolbar tabbar labels bottom>
            <!-- 商户登录 -->
            <f7Link v-if="isMerchant" tab-link="#view-page" :tab-link-active="state.currentTab === 'view-page'">
                <div class="h-full flex flex-col items-center justify-center" style="position: relative;" @click="MyMarkFlagFalse">
                    <img class="icon" src="./assets/home1_0.svg" alt="">
                    <img class="icon selected tab9-icon" src="./assets/home.svg" alt="">
                    <div class="icon selected tab9"></div>
                    <div class="title mt-1">首页</div>
                </div>
            </f7Link>
            <f7Link  v-if="isMerchant" tab-link="#view-shopOrder" :tab-link-active="state.currentTab === 'view-shopOrder'">
                <div class="h-full flex flex-col items-center justify-center" style="position: relative;" @click="cornerMark">
                    <img class="icon" src="./assets/tab3_0.svg" alt="">
                    <img class="icon selected tab9-icon" src="./assets/tabicon3.svg" alt="">
                    <div class="icon selected tab9"></div>
                    <div class="title mt-1">订单</div>
                </div>
            </f7Link>
            <!-- 正常用户登录 -->
            <f7Link v-if="!isMerchant" tab-link="#view-collection" :tab-link-active="state.currentTab === 'view-collection'">
                <div class="h-full flex flex-col items-center justify-center" style="position: relative;" @click="MyMarkFlagFalse">
                    <img class="icon" src="./assets/tab1_0.svg" alt="">
                    <!-- <img class="icon selected" src="./assets/tab1_1.svg" alt=""> -->
                    <img class="icon selected tab9-icon" src="./assets/tabicon1.svg" alt="">
                    <div class="icon selected tab9"></div>
                    <div class="title mt-1">收款</div>
                </div>
            </f7Link>
            <f7Link v-if="!isMerchant" tab-link="#view-offers" :tab-link-active="state.currentTab === 'view-offers'">
                <div class="h-full flex flex-col items-center justify-center" style="position: relative;" @click="MyMarkFlagFalse">
                    <img class="icon" src="./assets/tab2_0.svg" alt="">
                    <!-- <img class="icon selected" src="./assets/tab2_1.svg" alt=""> -->
                    <img class="icon selected tab9-icon" src="./assets/tabicon2.svg" alt="">
                    <div class="icon selected tab9"></div>
                    <div class="title mt-1">活动</div>
                </div>
            </f7Link>
            <f7Link v-if="!isMerchant" tab-link="#view-order" :tab-link-active="state.currentTab === 'view-order'">
                <div class="h-full flex flex-col items-center justify-center" style="position: relative;" @click="cornerMark">
                    <div v-if="todoList.newAll._object.newAll.totalCount != ''" class="badge1">
                        <span>{{ todoList.newAll._object.newAll.totalCount > 99 ? 99 : todoList.newAll._object.newAll.totalCount }}</span>
                    </div>
                    <img class="icon" src="./assets/tab3_0.svg" alt="">
                    <!-- <img class="icon selected" src="./assets/tab3_1.svg" alt=""> -->
                    <img class="icon selected tab9-icon" src="./assets/tabicon3.svg" alt="">
                    <div class="icon selected tab9"></div>
                    <div class="title mt-1">订单</div>
                </div>
            </f7Link>
            <f7Link tab-link="#view-user" :tab-link-active="state.currentTab === 'view-user'">
                <div class="h-full flex flex-col items-center justify-center" style="position: relative;" @click="MyMarkFlagFalse(),viewUser()">
                    <img class="icon" src="./assets/tab4_0.svg" alt="">
                    <!-- <img class="icon selected" src="./assets/tab4_1.svg" alt=""> -->
                    <img class="icon selected tab9-icon" src="./assets/tabicon4.svg" alt="">
                    <div class="icon selected tab9"></div>
                    <div class="title mt-1">我的</div>
                </div>
            </f7Link>
        </f7Toolbar>
        <!-- <f7View v-if="login.isLogin && login.refundTradeNo" stack-pages :url="`/order/${login.refundTradeNo}`" class="safe-areas" name="Detail"></f7View> -->
        <f7View id="view-page" tab stack-pages url="/homePage/index" :tab-active="state.currentTab === 'view-page'" @tab:show="state.currentTab = 'view-page'"></f7View>
        <f7View id="view-shopOrder" tab stack-pages url="/order/shopList" :tab-active="state.currentTab === 'view-shopOrder'" @tab:show="state.currentTab = 'view-shopOrder'"></f7View>
        <f7View id="view-collection" tab stack-pages url="/collection/new" :tab-active="state.currentTab === 'view-collection'" @tab:show="state.currentTab = 'view-collection'"></f7View>
        <f7View id="view-offers" tab stack-pages url="/offers/list" :tab-active="state.currentTab === 'view-offers'" @tab:show="state.currentTab = 'view-offers'"></f7View>
        <f7View id="view-order" tab stack-pages url="/order/list" :tab-active="state.currentTab === 'view-order'" @tab:show="state.currentTab = 'view-order'"></f7View>
        <f7View id="view-user" tab stack-pages url="/user/profile" :tab-active="state.currentTab === 'view-user'" @tab:show="state.currentTab = 'view-user'"></f7View>
    </f7Views>
</template>
<script lang="ts">
export const TabControllerKey: InjectionKey<TabController> = Symbol('TabController')
export interface TabController {
    setCurrentTab(index: number): void
    setCurrentTab(id: "view-page"|"view-shopOrder"|"view-collection"|"view-offers"|"view-order"|"view-user"): void
}
</script>
<script setup lang="ts">
import { f7Link, f7Toolbar, f7View, f7Views } from 'framework7-vue';
import { InjectionKey, onMounted, provide, reactive , ref } from 'vue';
import { storeToRefs } from 'pinia';
import {useTodoStore} from '../../stores/mark'
import { getCurrentInstance } from 'vue';
import { sharedLogin } from 'src/Controllers/LoginConrtoller';
const login = sharedLogin
const allTabs = ["view-page","view-shopOrder","view-collection", "view-offers", "view-order", "view-user"]
const role = ref(JSON.parse(localStorage.getItem("user"))?.accountType)
const isMerchant = role.value === 3 || role.value === 6
const state:any = reactive({
    currentTab:  isMerchant ? "view-page" : 'view-collection'
})

const todoStore  = useTodoStore()

const todoList:any = storeToRefs(todoStore)

const controller: TabController = {
    setCurrentTab(indexOrId) {
        if (typeof indexOrId === 'number') {
            state.currentTab = allTabs[indexOrId] ?? state.currentTab
        } else {
            state.currentTab = indexOrId
        }
    }
}

provide(TabControllerKey, controller)

const cornerMark =async() =>{
    await MyUpDate();
    await setTimeout(async()=>{
        await todoStore.OrderCount();
    },100)
}

const MyUpDate =async()=>{
    await todoStore.MyMarkFlag()
}

const MyMarkFlagFalse = async()=>{
    await todoStore.MyMarkFlagFalse()
    console.log(todoList.markFlag._object.markFlag);
}

const instance:any = getCurrentInstance();
//我的账号信息
const viewUser=()=>{
    instance?.proxy?.$bus.emit('wallet');
}

onMounted(()=>{
    todoStore.OrderCount();
})


</script>

<style scoped>
.title {
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #999EA7;
}

.tab-link-active .title {
    color: var(--f7-theme-color);
}

.tab9 {
    width: 26px;
    height: 26px;
    border-radius: 12px;
    margin: 2px;
    top: 2px;
    background-color: var(--f7-theme-color);
}

.tab9-icon{
    position: absolute;
    top: 12px;
}

.tab-link-active .icon {
    display: none;
}

.tab-link-active .icon.selected {
    display: block;
}

.icon {
    display: block;
}

.icon.selected {
    display: none;
}
.badge1{
    position: absolute;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    height: 16px;
    border-radius: 50%;
    color: #FFFFFF;
    background-color: #E34D59;
    top: 2px;
    left: 18px;
    width: 16px;
}
</style>
