<template>
    <RoundDesc class="m-4" :name="label ?? '密码'" divide >
        <input class="self-stretch flex-1 w-0" :type="data.pwdVisibility ? 'text' : 'password'"
               @input="emit('update:modelValue', $event!.target!.value)" :value="modelValue"
               :placeholder="placeholder ?? '请输入密码'" />
        <Switch v-if="modelValue.length" v-model="data.pwdVisibility" v-slot="{ isEnabled }">
            <img class="p-2" :src="isEnabled ? on : off" alt="">
        </Switch>
    </RoundDesc>
</template>

<script setup lang="ts">
import Switch from 'src/components/Inputs/Switch.vue';
import RoundDesc from 'src/components/RoundControls/RoundDesc.vue';
import { reactive } from 'vue';
import on from './assets/visible.svg'
import off from './assets/invisible.svg'

const props = defineProps<{
    label?: string
    placeholder?: string
    modelValue: string
}>();

const emit = defineEmits<{
    (event: 'update:modelValue', value: string): void
}>();

const data = reactive({
    pwdVisibility: false
});
</script>

<style scoped>

</style>
