import { Loader } from "../Loader"

type JSON = undefined | null | boolean | number | string | JSON[] | { [key: string]: JSON }
type QueryParams = ConstructorParameters<typeof URLSearchParams>[0]

declare module "../Loader" {
    interface Loader<Value, Params extends any[]> {
        get: Params extends Parameters<typeof fetch> ?
        <T = Value>(url: string, query?: QueryParams, options?: Omit<RequestInit, 'method'>) => Promise<T>
        : never
        post: Params extends Parameters<typeof fetch>?
        <T = Value>(url: string, body?: JSON, options?: Omit<RequestInit, 'method' | 'body'>) => Promise<T>
        : never
    }
}

Loader.prototype.get = function(url, query, options) {
    if (query) {
        const _url = new URL(url, 'about:/%/')
        new URLSearchParams(query).forEach((value, key) => _url.searchParams.append(key, value))
        url = _url.href
        if (_url.protocol === 'about:') {
            if (_url.pathname.startsWith('/%/')) {
                url = url.slice(9)
            } else {
                url = url.slice(6)
            }
        }
    }
    if (this.load.length === 1) {
        return this.load(new Request(url, {
            method: 'GET',
            ...options
        }))
    } else {
        return this.load(url, {
            method: 'GET',
            ...options
        })
    }
    
}

Loader.prototype.post = function (url, body, options) {
    if (this.load.length === 1) {
        return this.load(new Request(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            },
            ...options
        }))
    } else {
        return this.load(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            },
            ...options
        })
    }
}