import { resultData,http } from "src/http";

export function login(username: string, password: string, code: string, clientType: string) {
    return resultData.post<User>('/jhserver/user/app/v1/login', { username, password, code, clientType })
}

export function getUserList(phone: string) {
    return resultData.post('/jhserver/user/app/v1/getAlreadyBindUserList',{phone})
}

export function smslogin(username: string, smsCode: string, uid: string, code: string) {
    return resultData.post<User>('/jhserver/user/app/v1/smsLogin', { username, smsCode, uid,code })
}

export function getLoginPhoneSms(phone: string) {
    return resultData.post('/jhserver/user/app/v1/getLoginVerificationCode',{phone})
}

export function register(phone: string, password: string, smsCode: string) {
    return resultData.post<User>('/jhserver/user/app/v1/h5JdRegister', { phone, password, smsCode })
}

export function getRegisterPhoneSms(phone: string) {
    return resultData.post('/jhserver/user/app/v1/getJDRegisterSmsVerifyCode',{phone})
}

export function getColorTheme(param:any) {
    return http.resultData.post<any>('/jhserver/theme/web/v1/getThemeColor',param);
}

export function checkOpenChannel(param:any) {
    return http.resultData.post('/jhserver/ka/app/v1/checkOpenChannel',param);
}

export type User = {
    "accountType": number,
    "clientType": string,
    "clientUid": string,
    "headImage": string,
    "nickname": string,
    "permissions": Permission[],
    "roles": number[],
    "showCashier": boolean,
    "token": string,
    "uid": string,
    "username": string
}

export type Permission = {
    "children": Permission[],
    "component": string,
    "hidden": boolean,
    "icon": string,
    "menuName": string,
    "menuType": string,
    "name": string,
    "orderNum": number,
    "path": string
}
