import { Loader } from "../Loader"

export type Result<T = any> = {
    data: T
    success: boolean,
    code: string,
    msg: string
}

export class ResultError extends Error {
    constructor(public readonly result: Result) {
        super(result.msg)
    }
}

function extract<T>(result: Result<T>): T {
    if (result.success) {
        return result.data
    } else {
        throw new ResultError(result)
    }
}


declare module "../Loader" {
    interface Loader<Value, Params extends any[]> {
        resultData: Value extends Result<infer Data> ? () => Loader<Data, Params> : never
    }
}

Loader.prototype.resultData = function () {
    return this.mapValue(extract)
}