import { reactive, UnwrapNestedRefs } from "vue"
import { Loader } from "../Loader"

export class WithState<Value, Params extends any[]> extends Loader<void, Params> {
    value: Value | undefined = undefined
    error: Error | undefined = undefined

    get isLoading(): boolean {
        return !!this.loading
    }

    private loading: Promise<Value> | undefined = undefined

    constructor(public readonly origin: Loader<Value, Params>) {
        super()
    }

    async load(...args: Params): Promise<void> {
        this.loading = undefined
        this.error = undefined
        const loading = this.origin.load(...args)
        this.loading = loading
        try {
            const value = await this.loading
            if (loading !== this.loading) {
                return
            }
            this.value = value
            this.loading = undefined
        } catch (e: any) {
            this.error = e
            if (loading === this.loading) {
                this.loading = undefined
            }
            throw e
        }
    }

    reset() {
        this.value = undefined
        this.error = undefined
        this.loading = undefined
    }

    immediatelyLoad(...args: Params): WithState<Value, Params> {
        this.load(...args)
        return this
    }
}

declare module "../Loader" {
    interface Loader<Value, Params extends any[]> {
        withState(): WithState<Value, Params>
        reactive(): UnwrapNestedRefs<WithState<Value, Params>>
    }
}

Loader.prototype.withState = function () {
    return new WithState(this)
}

Loader.prototype.reactive = function () {
    return reactive(new WithState(this))
}

WithState.prototype.withState = function () {
    return this
}