import { Loader } from "../Loader";

export type PagedParams = {
    lastDataId?: number
    limit: number
}

export type Paged<T extends {id: number}> = {
    data: T[]
    lastDataId?: number
    limit: number
    total: number
}

export class PagedLoader<Value extends {id: number}, Params> extends Loader<void, [Params]> {

    value: Value[] | undefined = undefined

    error: Error | undefined = undefined
    defaultParams: Params | undefined = undefined
    private params: Params & PagedParams | undefined = undefined
    private page: Paged<Value> | undefined = undefined

    get total(): number | undefined {
        return this.page?.total
    }

    get hasMore(): boolean {
        return this.page !== undefined && this.page.data.length >= this.page.limit
    }

    get isLoading(): boolean {
        return this.isReloading || this.isLoadingMore
    }

    get isReloading(): boolean {
        return !!this.loading
    }

    get isLoadingMore(): boolean {
        return !!this.loadingMore
    }

    private loading: Promise<Paged<Value>> | undefined
    private loadingMore: Promise<Paged<Value>> | undefined

    constructor(public readonly origin: Loader<Paged<Value>, [Params & PagedParams]>) {
        super()
    }

    async load(params: Params): Promise<void> {
        console.log("load", params)
        this.loading?.cancel()
        this.loadingMore?.cancel()
        this.error = undefined
        const _params = Object.assign({
            limit: 10
        }, this.defaultParams, params)
        const loading = this.origin.load(_params)
        this.loading = loading
        try {
            const result = await loading
            result.data = result.data ?? []
            this.value = Array.from(result.data)
            this.params = _params
            this.page = result
            this.loading = undefined
        } catch (e: any) {
            this.error = e
            if (loading === this.loading) {
                this.loading = undefined
            }
            throw e
        }
    }

    async loadMore(): Promise<void> {
        if (this.isLoading) { return }
        if (!this.hasMore) {
            throw new Error("No more data to load")
        }
        this.error = undefined
        const _params = Object.assign({}, this.defaultParams, this.params)
        _params.lastDataId = this.value![this.value!.length - 1].id
        const loading = this.origin.load(_params)
        this.loadingMore = loading
        try {
            const result = await loading
            this.value = this.value!.concat(result.data)
            this.params = _params
            this.page = result
            this.loadingMore = undefined
        } catch (e: any) {
            this.error = e
            if (loading === this.loadingMore) {
                this.loadingMore = undefined
            }
            throw e
        }
    }
}

declare module "../Loader" {
    interface Loader<Value, Params extends any[]> {
        paged: [PagedParams] extends Params ?
        Value extends Paged<infer T> ?
        () => PagedLoader<T, Omit<Params[0], keyof PagedParams>> :
        never :
        never
    }
}

Loader.prototype.paged = function () {
    return new PagedLoader(this)
}
