import { sharedLogin } from './Controllers/LoginConrtoller'
import { makeLoader, Result } from './Loader'
import { ResultError } from './Loader/Loaders/ResultData'

export const response = makeLoader(fetch as RequestToResponse)
    .modifyParams(setToken)
    .mapParams(paramsToRequest)
    .modifyParams(setBase(import.meta.env.VITE_APP_API_URL))

export const response1 = makeLoader(fetch as RequestToResponse)
.mapParams(paramsToRequest)
// .modifyParams(setBase(import.meta.env.VITE_APP_API_URL))

export const json = response.mapValue(response => response.json())
export const json1 = response1.mapValue(response => response.json())

export const text = response.mapValue(response => response.text())

export const blob = response.mapValue(response => response.blob())

export const arrayBuffer = response.mapValue(response => response.arrayBuffer())

export const formData = response.mapValue(response => response.formData())

export const result = json.setValueType<Result>()

export const resultData = result.resultData().onError(async error => {
    if (error instanceof ResultError) {
        if (error.result.code === "40015") {
            sharedLogin.setLogin(null)
        }
    }
})

export const uploadFile = makeLoader(async (file: File) => {
    const fileName = Date.now() + file.name.substring(file.name.lastIndexOf('.'), file.name.length)
    const params = await resultData.post('/jhserver/image/app/v1/getUploadToken', { fileName })
    const form = new FormData()
    form.append('key', params.key)
    form.append('token', params.token)
    form.append('fname', fileName)
    form.append('file', file, file.name)
    const response = await json.load(params.url, { method: 'POST', body: form })
    return params.host + response.key
})

export const http = {
    response,
    json,
    json1,
    text,
    blob,
    arrayBuffer,
    formData,
    result,
    resultData,
    uploadFile
}


type RequestToResponse = (_: Request) => Promise<Response>
type RequetsParams = [input: string | URL, init?: RequestInit]

function setBase(url: string | URL) {
    return (...[input, init]: RequetsParams): RequetsParams => {
        return [new URL(input, url), init]
    }
}

function setToken(request: Request): [Request] {
    const token = localStorage.getItem('token')
    token && request.headers.set('Authorization', token)
    request.headers.set('version', import.meta.env.VITE_APP_VERSION)
    return [request]
}

function paramsToRequest(...args: RequetsParams): [Request] {
    return [new Request(...args)]
}