<template>
    <f7Button :fill="fill" large round class="action-button" :class="{ disabled: disabled }" :href="href" @click="performAction"
            @contextmenu.prevent="">
        <f7Preloader v-if="data.isLoading" :color="fill ? 'white' : 'primary'" />
        <slot v-else></slot>
    </f7Button>
</template>

<script setup lang="ts">
import { f7Button, f7Preloader } from 'framework7-vue';
import { reactive } from 'vue';
const emit = defineEmits<{
    (event: 'click'): void
}>()

const props = defineProps<{
    fill?: boolean
    disabled?: boolean
    href?: string
    action?: () => Promise<unknown> | void,
    toast?: boolean
}>()


const data = reactive({
    isLoading: false
})

async function performAction() {
    emit('click')
    if (props.action) {
        if (props.toast) {
            try {
                const p = props.action()
                await Promise.resolve().then(() => p)
            } catch (error: any) {
                window.f7.toast.show({
                    text: error.message,
                    closeTimeout: 3000
                })
                console.log(error)
            }
        } else {
            if (data.isLoading) return;
            data.isLoading = true
            try {
                await Promise.resolve(props.action())
            } finally {
                data.isLoading = false
            }
        }
    }
}

</script>

<style scoped>
.action-button {
    flex: none;
}

.action-button:not(.button-fill) {
    --f7-button-bg-color: var(--content-bg-color);
    --f7-button-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.03);
}

.action-button.button-fill {
    --f7-button-box-shadow:  0px 4px 8px 0px var(--f7-md-primary-container);
}

.action-button.active-state {
    --f7-button-box-shadow: ;
}
</style>