<template>
    <div class="qr-code" ref="box" :data-content="content">
        <slot/>
    </div>
</template>

<script setup lang="ts">
import QRCodeGen from 'qrcode-generator';
import { onMounted, onUpdated, ref, useSlots } from 'vue'

const box = ref<HTMLDivElement>()
const slots = useSlots()

const content = ref('')

onUpdated(updateDom)
onMounted(updateDom)

function updateDom() {
    if (!slots.default) return;
    const vnode = slots.default()[0]
    if (!vnode) return;
    const qr = QRCodeGen(0, 'H')
    content.value = vnode.children as string
    qr.addData(content.value)
    qr.make()
    box.value!.innerHTML = qr.createSvgTag()
}

</script>

<style scoped>
:deep(svg) {
    display: block;
    height: 100%;
    width: 100%;
    border: none;
}
.qr-code {
    height: 100%;
    width: 100%;
}
</style>
