<template>
    <div class="board-select">
        <slot>
            <select>
                <option>请选择</option>
            </select>
        </slot>
        <svg class="icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_50_7406)">
                <path d="M4.94 5.72668L8 8.78002L11.06 5.72668L12 6.66668L8 10.6667L4 6.66668L4.94 5.72668Z"
                      fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_50_7406">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    </div>

</template>

<script setup lang="ts">

</script>

<style scoped>
.board-select {
    position: relative;
    display: inline-block;
}

.board-select :slotted(select)  {
    height: 32px;
    background: #FFFFFF;
    border: 0.4px solid #9B9B9B;
    border-radius: 4px;
    padding-left: 12px;
    padding-right: 32px;

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--f7-label-text-color);
}

.board-select .icon {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    height: 16px;
    bottom: 16px;
    pointer-events: none;
}

.board-select :slotted(select):focus {
    background: var(--f7-page-bg-color);
}

.board-select :slotted(select[disabled]) {
    background: var(--f7-page-bg-color);
    opacity: 0.5;
}
</style>