<template>
    <div class="red-envelope-code w-[156px] h-[156px]" @click.capture="refresh">
        <img v-if="!url" class="absolute h-full w-full z-1" src="./assets/error.svg" alt="">
        <!-- 控制布局，正好只显示二维码，其他部分全部剪裁掉 -->
         <div v-if="state.url">
            <div v-if="state.urlType == 'qrcode'" class="content">
                <QrCode class="QrCode">{{ state.url }}</QrCode>
            </div>
            <img v-if="state.urlType == 'image'" class="h-[156px] w-[156px] mx-auto pointer-events-none" :src="state.url" alt="">
            <iframe v-if="state.urlType == 'iframe'" title="" scrolling="no"
                class="mt-[-90px] h-[246px] w-[156px] mx-auto pointer-events-none" :src="state.url"></iframe>
         </div>
        <img v-else class="w-[156px] h-[156px]" src="./assets/disabled.svg" alt="">
        <div v-if="state.isLoading" class="absolute top-0 h-full w-full flex justify-center items-center bg-white">
            <f7Preloader></f7Preloader>
        </div>
    </div>
</template>

<script setup lang="ts">
import { f7Preloader } from 'framework7-vue';
import { nextTick, reactive, watch } from 'vue';
import QrCode from 'src/components/QrCode.vue';

const props = defineProps<{
    url?: string;
    urlType?: string
}>();

const state = reactive({
    isLoading: false,
    url: props.url,
    urlType: props.urlType
});

watch(() => props.url, refresh)

refresh()

const isLink=(url:any)=> {
    return /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(url);
}

function refresh() {
    if (props.url) {
        state.isLoading = true;
        Promise.delay(1000).then(() => state.isLoading = false)
        state.url = '';
        nextTick(() => {
            state.url = props.url;
        })
    }
}
</script>

<style scoped>
.red-envelope-code {
    overflow: hidden;
    position: relative;
}
</style>
