import { Router } from "framework7/types"
import Index from './index.vue'
const Step1 = () => import('./步骤/Step1.vue')
const Step2 = () => import('./步骤/Step2.vue')
const Step3 = () => import('./步骤/Step3.vue')
const Step4 = () => import('./步骤/Step4.vue')
const picture = () => import('./步骤/picture.vue')
const StoreNew = () => import('./步骤/StoreNew.vue')
const home = () => import('../Home/index.vue')
const routes: Router.RouteParameters[] = [
    {
        path: '/FUIOU',
        component: Index
    },
    {
        name:'step1FUIOU',
        path: '/FUIOU/step1',
        asyncComponent: Step1
    },
    {
        path: '/FUIOU/step2',
        asyncComponent: Step2
    },
    {
        path: '/FUIOU/step3',
        asyncComponent: Step3
    },
    {
        path: '/FUIOU/step4',
        asyncComponent: Step4
    },
    {
        path: '/FUIOU/picture',
        asyncComponent: picture
    },
    {
        path: '/FUIOU/StoreNew',
        asyncComponent: StoreNew
    },
    {
        path: '/home',
        asyncComponent: home
    },
]

export default routes
