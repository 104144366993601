<template>
    <div v-if="order.payeeInfo">
        <RoundCard>
            <RoundCardHeader title="支付信息"></RoundCardHeader>
            <form class="p-4">
                <slot name="支付信息"></slot>
                <div class="flex justify-between items-center">
                    <span class="flex-none">收款方式</span>
                    <SelectStyle>
                        <select class="w-[130px]" v-model="props.selections.payee" :disabled="order.disabledChange">
                            <option :value="undefined">请选择</option>
                            <option v-for="payee in order.payeeInfo" :value="payee">{{ payee.value }}</option>
                        </select>
                    </SelectStyle>
                </div>
                <div v-if="props.selections.payee" class="flex justify-between items-center mt-3">
                    <span class="flex-none">支付方式</span>
                    <SelectStyle>
                        <select class="w-[130px]" v-model="props.selections.pay"  :disabled="order.disabledChange">
                            <option :value="undefined">请选择</option>
                            <option v-for="pay in props.selections.payee.payInfo" :value="pay">{{ pay.value }}</option>
                        </select>
                    </SelectStyle>
                </div>
                <div v-if="props.selections.pay?.numList" class="flex justify-between items-center mt-3">
                    <span class="flex-none">分期期数</span>
                    <SelectStyle>
                        <select class="w-[130px]" v-model="props.selections.num" :disabled="order.disabledChange">
                            <option :value="undefined">请选择</option>
                            <template v-for="num in props.selections.pay.numList">
                                <option v-if="num.show" :value="num">{{ num.value }}</option>
                            </template>
                        </select>
                    </SelectStyle>
                </div>
                <!-- <div v-if="props.selections.pay?.key === 'LIANLIAN'" class="flex justify-between items-center mt-3">
                    <span class="flex-none">分期银行</span>
                    <SelectStyle>
                        <select class="w-[130px]" v-model="props.selections.bankCode" :disabled="order.disabledChange">
                            <option :value="undefined">请选择</option>
                            <template v-for="item in bankList">
                                <option :value="item.bankCode">{{ item.bankName }}</option>
                            </template>
                        </select>
                    </SelectStyle>
                </div> -->
                <div v-if="props.selections.pay?.key === 'LIANLIAN'" class="flex justify-between items-center mt-3">
                    <span class="flex-none">分期银行</span>
                    <SelectStyle>
                        <div class="w-[130px] truncate simSelect" @click="isOpenedBank()" v-if="!order.disabledChange">
                            {{ props.selections.bankCode ? bankName : '请选择' }}
                        </div>
                        <div class="w-[130px] truncate simSelect" style="background: var(--f7-page-bg-color);opacity: 0.5;" v-else>
                            {{ props.selections.bankCode ? bankName : '请选择' }}
                        </div>
                    </SelectStyle>
                </div>
                <div v-show="props.selections.num && props.selections.num.isFeeSeller" class="flex justify-between items-center mt-3">
                    <span class="flex-none" ref="tooltip">是否商贴 <img class="inline align-text-bottom"
                             src="./assets/question.svg" alt=""></span>
                    <f7Toggle v-model:checked="props.selections.condition" :disabled="order.disabledChange || !props.selections.num?.change"></f7Toggle>
                </div>
            </form>
        </RoundCard>
        <div class="flex justify-around realTime" v-if="props.selections.pay?.key !== 'HBXX' && props.selections.payee?.key !== 'INS' && props.selections.payee != undefined">
            <div>
                <span style="color: #1E1E1E;">用户实付</span>
                <span class="font-bold"> ￥ {{  insCal.value?.totalAmount ?? '...'  }}</span>
            </div>
            <div style="color: #DCDCDC;">|</div>
            <div>
                <span style="color: #1E1E1E;">商家实收</span>
                <span class="font-bold"> ￥ {{  insCal.value?.sellerAmount ?? '...'  }}</span>
            </div>
        </div>

        <div class="activity" v-if="activity.show">
            {{ activity.content }}<span style="color: var(--f7-theme-color);">{{ activity.money }}</span>
        </div>

        <RoundCard class="mt-4" v-if="props.selections.pay?.insCal">
            <RoundCardHeader title="分期信息"></RoundCardHeader>
            <div class="p-4 flex justify-center" @click="insCal.load()">
                <div class="flex-none flex flex-col items-center">
                    <span>每期还款(元)</span>
                    <span class="mt-3">{{  insCal.value?.amount ?? '...'  }}</span>
                </div>
                <div class="flex-none flex flex-col items-center">
                    <span>　×　</span>
                    <span class="mt-3">　×　</span>
                </div>
                <div class="flex-none flex flex-col items-center">
                    <span>期数</span>
                    <span class="mt-3">{{  insCal.value?.num ?? '...'  }}</span>
                </div>
                <div class="flex-none flex flex-col items-center">
                    <span>　=　</span>
                    <span class="mt-3">　=　</span>
                </div>
                <div class="flex-none flex flex-col items-center">
                    <span>总金额(元)</span>
                    <span class="mt-3">{{  insCal.value?.totalAmount ?? '...'  }}</span>
                </div>
            </div>
        </RoundCard>

        <f7Actions v-model:opened="isOpened">
                <RoundCard  style="border-radius: 10px 10px 0 0;">
                    <RoundCardHeader title="请选择分期银行"></RoundCardHeader>
                    <div style="height: 80vh;overflow: hidden;overflow-y: auto;">
                        <!-- 搜索 -->
                        <div class="headerInput w-full">
                            <div class="flex">
                                <div class="icon-inp">
                                    <img class="w-[12px]" src="../../首页/assets/search.svg" alt="">
                                </div>
                                <f7-input class="text-input" label="搜索" type="text" placeholder="请输入银行名称"  v-model:value="searchBankName" @input:clear="searchBankList" @change="searchBankList" clear-button>
                                    <template v-slot:media>
                                    <f7-icon icon="demo-list-icon"></f7-icon>
                                    </template>
                                </f7-input>
                                <div class="input-right text-inp" @click="searchBankList">&nbsp;|&nbsp;搜索</div>
                            </div>
                        </div>
                        <div class="content-bank" v-for="item in bankList" @click="selectBank(item)">
                            <span>{{ item.bankName }}</span>
                            <div class="flex">
                                <span v-if="item.discounts?.length > 1" class="down" @click.stop="item.down = !item.down">{{ item.down ? '收起' : '更多'}}</span>
                                <div v-if="item.discounts?.length > 1" class="arrows" :style="{transform : item.down ? 'rotate(270deg)' : 'rotate(90deg)'}"></div>
                                <div>
                                    <template v-for="(n,index) in item.discounts">
                                        <div class="content-bank-right" @click.stop="item.down = !item.down" v-if="index < 1 || item.down">{{ n }}</div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </RoundCard>
            </f7Actions>
    </div>

</template>

<script setup lang="ts">
import { f7Toggle , f7Actions , f7Input } from 'framework7-vue';
import SelectStyle from 'src/components/Inputs/BoardSelect.vue';
import RoundCard from 'src/components/RoundControls/RoundCard.vue';
import RoundCardHeader from 'src/components/RoundControls/RoundCardHeader.vue';
import { makeLoader } from 'src/Loader';
import { onMounted, ref, watch } from 'vue';
import * as api from '../api';
import { forEach } from 'lodash';
const props = defineProps<{
    amount1?:any,
    flag?:boolean | undefined,
    order: api.OrderDetail,
    selections: {
        payee: api.PayeeInfo | undefined,
        pay: api.PayInfo | undefined,
        num: api.NumInfo | undefined,
        bankCode:string  | undefined,
        provinceCode:string  | undefined,
        discountChannel:boolean | undefined,
        condition: boolean | undefined,
        customerPhone:string | undefined,
        outCashierNo:string | undefined,
    }
}>();

const emit = defineEmits<{
    (event: 'update:selections', selections: typeof props.selections): void
}>()

const isOpened = ref(false)

const defaultPayee = props.order.payeeInfo?.find(payee => payee.choose)
const defaultPay = defaultPayee?.payInfo.find(pay => pay.choose)
const defaultNum = defaultPay?.numList?.find(num => num.choose)
const bankCode = props.selections.bankCode || props.order.bankCode
const provinceCode = props.selections.provinceCode || props.order.provinceCode
const discountChannel= props.selections.discountChannel

emit('update:selections', {
    payee: defaultPayee,
    pay: defaultPay,
    num: defaultNum,
    bankCode:bankCode,
    provinceCode:provinceCode,
    discountChannel:discountChannel,
    customerPhone:props.order.customerPhone,
    outCashierNo:props.order.outCashierNo,
    condition: !!defaultNum?.condition
    // condition: defaultNum?.isFeeSeller ? !!defaultNum.condition : undefined
})

watch(() => props.selections.payee, payee => {
    const newSelections = Object.assign({}, props.selections)
    newSelections.pay = payee?.payInfo.find(pay => pay.choose) ?? payee?.payInfo[0]
    emit('update:selections', newSelections)
})
watch(() => props.selections.pay, pay => {
    if(pay?.key !== 'LIANLIAN'){
        props.selections.discountChannel = false;
        props.selections.provinceCode = undefined;
    }
    insCal.load();
    const newSelections = Object.assign({}, props.selections)
    newSelections.num = pay?.numList?.find(num => num.choose) ?? pay?.numList?.[0]
    emit('update:selections', newSelections)
})
watch(() => props.selections.num, num => {
    const newSelections = Object.assign({}, props.selections)
    newSelections.condition =  !!num?.condition 
    // newSelections.condition = num?.isFeeSeller ? !!num.condition : undefined
    getComputeRedPacket();
    if(props.selections.pay?.key === 'LIANLIAN'){
        getBankList(); //信用卡切换期数获取银行
        insCal.load();
    }
    emit('update:selections', newSelections)
    if (props.selections.pay?.insCal) {
        insCal.load()
    }
})
watch(() => props.selections.bankCode, bankCode => {
    getComputeRedPacket();
    // if (props.selections.pay?.key === 'LIANLIAN' && props.selections.bankCode !== undefined) {
    //     insCal.load();
    // }
    if (props.selections.pay?.key === 'LIANLIAN') {
        insCal.load();
    }
    const newSelections = Object.assign({}, props.selections)
    emit('update:selections', newSelections)
})

watch(() => props.selections.condition, (newCondition, oldCondition) => {
    getComputeRedPacket();
    if(props.selections.pay?.key === 'LIANLIAN'){
        getBankList(); //信用卡切换期数获取银行
        insCal.load();
    }
  if(props.selections.pay?.insCal){
    insCal.load();
  }
})

watch(() => props.amount1, (newAmount1, oldAmount1) => {
    if (props.selections.pay?.insCal || props.selections.pay?.key === 'LIANLIAN') {
        getBankList();
        insCal.load();
    }
    getComputeRedPacket();
})

const searchBankName = ref('');
const bankList = ref<any>([]);

const getBankList=()=>{
    let isFeeSeller = props.selections?.condition ? 1 : 0;
    api.getBankList({
        'amount':props.amount1 || props.order.amount,
        'num':props.selections.num?.key,
        'isFeeSeller':isFeeSeller           //isFeeSeller 1商户贴息  0顾客贴息
    }).then((res)=>{
        props.selections.discountChannel = res.discountChannel;
        props.selections.provinceCode = res.provinceCode;
        bankList.value = [];
        columns.value = [];
        forEach(res.bankList,(item)=>{
            item.down = false;
            bankList.value.push(item);
            columns.value.push(item);
        })
    }).catch(()=>{
        bankList.value = [];
        columns.value = [];
    })
    props.selections.bankCode = undefined;
    bankName.value = '请选择'
}

const isOpenedBank=()=>{
    searchBankName.value = '';
    isOpened.value = true;
    getBankList();
}
const columns = ref<any>([]);
//银行搜索
const searchBankList=()=>{
    let list = []
        for(let i = 0;i<columns.value.length;i++){
            if(columns.value[i].bankName.includes(searchBankName.value)){
                list.push(columns.value[i])
            }
        }
        bankList.value = list;
}

const bankName = ref<any>('');
const selectBank=(item:any)=>{
    bankName.value = item.bankName;
    props.selections.bankCode = item.bankCode;
    isOpened.value = false;
}

const insCal:any = makeLoader(api.compute).mapParams(() => {
    if (!props.order.amount) {
        if(!props.amount1){
            insCal.value={};
            throw new Error("请填写金额")
        }
    }
    if (!props.selections.payee) {
        throw new Error("请选择收款方式")
    }
    if (!props.selections.pay) {
        throw new Error("请选择支付方式")
    }
    if (!props.selections.num) {
        throw new Error("请选择分期期数")
    }
    return [{
        'amount': props.order.amount || props.amount1,
        'isFeeSeller': props.selections.condition ? 1 : 0,
        'num': parseInt(props.selections.num.key),
        'payKey': props.selections.pay.key,
        'payeeKey': props.selections.payee.key,
        'tradeNo': props.order.tradeNo,
        'bankCode': props.selections.bankCode,
        'provinceCode': props.selections.provinceCode,
        'discountChannel':props.selections.discountChannel
    }]
}).reactive()

if (props.selections.pay?.insCal) {
    insCal.load()
}

const tooltip = ref<HTMLElement>()

 const activity = ref({
    content: "",
    money: "",
    show: false
 })

const getComputeRedPacket=async()=>{
    let res = await api.computeRedPacket({
        'amount': props.order.amount || props.amount1,
        'isFeeSeller': props.selections.condition ? 1 : 0,
        'num': props.selections.num?.key,
        'payKey': props.selections.pay?.key,
        'payeeKey': props.selections.payee?.key,
        'tradeNo': props.order.tradeNo,
        'bankCode': props.selections.bankCode
    })
    activity.value = res;
}

onMounted(() => {
    props.selections.bankCode = props.order.bankCode
    props.selections.provinceCode = props.order.provinceCode
    bankName.value = props.order.bankName
    const tooltipInstance = window.f7.tooltip.create({
        targetEl: tooltip.value!,
        text: '商户是否承担分期手续费',
        trigger: 'click'
    })
    return () => {
        tooltipInstance.destroy()
    }
})
</script>

<style scoped>
.realTime{
    padding: 12px 16px 12px 16px;
    border-radius: 0px 0px 8px 8px;
    color: #222222;
    background-color: var(--f7-md-surface-1);
}
.activity{
    margin-top: 12px;
    padding: 8px 16px 8px 16px;
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px 0px #00000008;
}
.content-bank{
    font-family: PingFang SC;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    font-size: 14px;
    font-weight: 400;
    line-height: 24.8px;
    padding: 16px;
    border-bottom: 1px solid #E6EBF2;
    text-align: left;
    color: #081828
}
.content-bank-right:last-child{
    margin-bottom: 0;
}
.content-bank-right{
    color: var(--f7-theme-color);
    border: 1px solid var(--f7-theme-color);
    border-radius: 4px;
    padding: 3px 8px;
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: center;
    margin-bottom: 8px;
}
.headerInput{
    height: 48px;
    padding: 8px 16px 8px 16px;
    background-color: #FFFFFF;
}
.text-input{
    display: flex;
    width: 100%;
    height: 32px;
    justify-content: space-between;
    background-color: #f8f8f8;
}
.border-inp{
    display: flex;
    align-items: center;
    padding-left: 8px;
    color: #DCDCDC;
    background-color: #f8f8f8;
}
.icon-inp{
    display: flex;
    justify-content: space-evenly;
    background-color: #f8f8f8;
    border-radius: 8px 0 0 8px;
    width: 28px;
}
.input-right{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: var(--f7-theme-color);
    background-color: #f8f8f8;
    width: 84px;
    border-radius: 0 8px 8px 0;
}
.simSelect{
    height: 32px;
    background: #FFFFFF;
    border: 0.4px solid #9B9B9B;
    border-radius: 4px;
    padding-left: 12px;
    padding-right: 32px;
    line-height: 32px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: var(--f7-label-text-color);
}
.down{
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: 400;
    line-height: 24.8px;
    height: 24.8px;
    margin-right: 4px;
    text-align: center;
    color: var(--f7-theme-color);
}
.arrows{
    height: 10px;
    margin-top: 8px;
    margin-right: 8px;
    transform: rotate(90deg);
    color: var(--f7-theme-color);
    background-color: currentColor;
    mask-image: url(../../收款/活动收款/assets/arrows.svg);
    margin: auto;
}
</style>
