import { http } from "src/http"
// import { appid } from "src/pages/登录/api"
import { inWeixin } from "./EnvironmentDetect"
import { externalImport } from "./ExternalSdkLoaders"
declare global {
    interface Window {
        wx: Wx
    }

    interface Wx {
        miniProgram?: MiniProgram

        config(config: {
            debug?: boolean
            appId: string
            timestamp: number
            nonceStr: string
            signature: string
            jsApiList: string[]
        }): void
        ready(callback: (...args: any[]) => void): void
        error(callback: (error: any) => void): void

        chooseWXPay(params: WxPayParams & BaseParams): void
        scanQRCode(params: ScanQrCodeParams & BaseParams): void
        getLocation(params: GetLocationParams & BaseParams): void
        getFuzzyLocation(params: GetLocationParams & BaseParams): void
        closeWindow(): void
    }

    type BaseParams = {
        success?: (res: any) => void
        fail?: (error: any) => void
        complete?: () => void
        cancel?: () => void
    }
}

interface MiniProgram {
    reLaunch(params: BaseParams & {
        url: string
    }): void
    redirectTo(params: BaseParams & {
        url: string
    }): void
    navigateTo(params: BaseParams & {
        url: string
        events?: Record<string, any>
    }): void
    navigateBack(params: BaseParams & {
        delta: number
    }): void
}

type WxPayParams = {
    timestamp: number // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
    nonceStr: string // 支付签名随机串，不长于 32 位
    package: string // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
    signType: string // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
    paySign: string // 支付签名
}

type ScanQrCodeParams = {
    needResult?: 0 | 1 // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
    scanType?: ('qrCode' | 'barCode')[] // 可以指定扫二维码还是一维码，默认二者都有
}

type GetLocationParams = {
    type: 'wgs84' | 'gcj02' // 默认为wgs84的 gps 坐标，如果要返回直接给 openLocation 用的火星坐标，可传入'gcj02'
}

async function loadWxsdk(): Promise<any> {
    // if (!inWeixin()) {
    //     throw new Error('请在微信中打开此页面')
    // }
    try {
        try {
            await externalImport('https://res.wx.qq.com/open/js/jweixin-1.6.0.js')
        } catch {
            await externalImport('https://res2.wx.qq.com/open/js/jweixin-1.6.0.js')
        }
    } catch {
        await Promise.delay(5000)
        await loadWxsdk()
    }
    return window.wx
}

export async function wxsdk(): Promise<Wx> {
    const wx = window.wx ?? await loadWxsdk()
    // return wx //小程序中不需要签名
    const currentUrl = encodeURIComponent(window.location.href)
    const result = await http.json1.post(`/smsjsapi/api/wx/open/getJsapiSignature?url=${currentUrl}`)
    console.log(result)
    const { appId, nonceStr, signature, timestamp } = result.data
    await new Promise((resolve, reject) => {
        wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: appId, // 必填，公众号的唯一标识
            timestamp: timestamp, // 必填，生成签名的时间戳
            nonceStr: nonceStr, // 必填，生成签名的随机串
            signature: signature,// 必填，签名
            jsApiList: ['chooseWXPay', 'scanQRCode', 'getLocation'] // 必填，需要使用的JS接口列表
        })
        wx.ready(resolve);
        wx.error(reject);
    })  
    return wx
}



export async function chooseWxPay(params: WxPayParams): Promise<any> {
    const wx = await wxsdk()
    return new Promise((resolve, reject) => {
        wx.chooseWXPay({
            ...params,
            success: resolve,
            fail: (error) => reject(new Error(JSON.stringify(error))),
            cancel: () => reject(new Error('取消支付'))
        })
    })
}

export async function scanQRCode(params: ScanQrCodeParams = {}): Promise<string> {
    const wx = await wxsdk()
    return new Promise((resolve, reject) => {
        wx.scanQRCode({
            ...params,
            success: (res) => resolve(res.resultStr),
            fail: (error) => reject(new Error(JSON.stringify(error))),
            cancel: () => reject(new Error('已取消'))
        })
    })
}

export async function getLocation(params: GetLocationParams = { type: 'wgs84' }): Promise<{
    latitude: number // 纬度，浮点数，范围为90 ~ -90
    longitude: number // 经度，浮点数，范围为180 ~ -180。
    speed: number // 速度，以米/每秒计
    accuracy: number // 位置精度
}> {
    console.log(2)
    const wx = await wxsdk()
    console.log(3)
    return new Promise((resolve, reject) => {
        console.log(4, wx)
        wx.getLocation({
            ...params,
            success: resolve,
            fail: (error) => reject(new Error(JSON.stringify(error))),
            cancel: () => reject(new Error('已取消'))
        })
        console.log(5,wx)
    })
}

export async function closeWindow() {
    const wx = await wxsdk()
    wx.closeWindow()
}

export { }