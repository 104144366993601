<template>
    <Page v-bind="$props" title="首页" navbar tab-root>
        <template #nav-right>
            <ExitMenuLink/>
        </template>
        <!-- 头部 -->
        <div class="text-center flex bg" style="background-color: antiquewhite;position: relative;">
            <div style="margin: 26px 16px 0 24px;">
                <img src="./assets/logo.svg" alt="">
            </div>
            <div>
                <div class="text-top" style="">欢迎您</div>
                <div class="text-bottom" style="">{{ data.nickname }}</div>
            </div>
            <div style="position: absolute;width: 100%;height: 142px;top: 0;">
                <img src="./assets/bg1.png" alt="" style="width: 100%;height: 142px;">
            </div>
        </div>
        
        <!-- 数据显示 -->
        <div style="position: relative;margin-top: -18px;">
            <RoundCardHeader title="交易数据" style="background-color: #FFFFFF;border-radius: 10px 10px 0px 0px;"></RoundCardHeader>
            <div class="dateBg">
                <DateInput style="height: 24px;" v-model:value="date" type="date" format="YYYY-MM-DD" @update:value="TransactionData"></DateInput>
            </div>
        </div>
        
        <div style="background-color: #FFFFFF;">
            <div class="text-center moneyColor">{{ data.value.transactionVolume || '0.00' }}</div>
            <div class="text-center moneyColor-1">交易额（元）</div>
            <div class="flex" style="justify-content: space-around;">
                <div class="dealLeft">
                    <div class="dealLeft-1">
                        <div class="dealLeft-icon1"></div>
                    </div>
                    <div>
                        <div class="dealLeft-2">{{ data.value.transactionNum || '0' }}</div>
                        <div class="dealLeft-3">交易笔数</div>
                    </div>
                </div>
                <div class="dealLeft">
                    <div class="dealLeft-1">
                        <div class="dealRight-icon2"></div>
                    </div>
                    <div>
                        <div class="dealLeft-2">{{ data.value.storeNum || '0' }}</div>
                        <div class="dealLeft-3">交易门店数</div>
                    </div>
                </div>
            </div>
        </div>
       <!-- 模块 -->
       <RoundCardHeader title="常用服务" style="background-color: #FFFFFF;"></RoundCardHeader>
       <div class="flex serve">
         <img src="./assets/serveLeft.svg"  @click="roleLeft()" alt="">
         <img src="./assets/serveRight.svg"  @click="roleRight()" alt="">
       </div>
       <img style="width: 95%;margin:8px auto 0;" src="./assets/Refund-approval.svg"  @click="RefundApproval()" alt="">
       <!-- 提示 -->
       <div v-if="false">
            <div class="warp">
                <div class="rect">
                    <div>提示</div>
                    <div>您暂未开通支付宝通道，无法操作~</div>
                </div>
            </div>
       </div>

       <!-- 办单红包弹窗 -->
       <div v-if="ActivityShow">
            <div class="warp">
                <div class="rect1" @click.stop="toActivityDetail()">
                    <div style="position: absolute;bottom: -50px;">
                        <img @click.stop="ActivityShow = false" src="./assets/fork.svg" alt="">
                    </div>
                </div>
            </div>
       </div>

    </Page>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import { Router } from 'framework7/types';
import { type } from 'os';
import Page from 'src/components/Pages/Page.vue';
import DateInput from 'src/components/Inputs/DateInput.vue';
import RoundCardHeader from 'src/components/RoundControls/RoundCardHeader.vue';
import { defineProps, onMounted, reactive , ref } from 'vue';
import { makeLoader } from 'src/Loader';
import { sharedLogin } from 'src/Controllers/LoginConrtoller';
import ExitMenuLink from 'src/pages/登录/登录/ExitMenuLink.vue';
import { getTransactionData , getPermissionData , getRegisterState , getActivityRedPacketShow } from './api'
import { f7Icon } from 'framework7-vue';
const login = sharedLogin

const props = defineProps<{
    f7router: Router.Router
    f7route: Router.Route
}>();

const date = ref(new Date())
const time = ref()

const data:any = reactive({
    value:{},
    nickname:'xxxxx公司欢迎您',
    role:{},
    state:null
});

type NavigateProps = {
  "path": "",
  "query": {}
}

function navigate (url:any)  {
    props.f7router.navigate(url)
}

const PermissionData=async()=>{
    let res = await getPermissionData()
    data.role = res
}

const RegisterState=async()=>{
    let res = await getRegisterState()   //0不跳转 1京东白条 2支付宝 3信用卡
    data.state = res.state;
    if(res.state === 1){
        navigate('/manualEntry')
    }else if(res.state === 2){
        navigate('/manualZFBEntry')
    }else if(data.state === 3){
        navigate('/creditCard')
    }
}

const roleLeft=()=>{
    PermissionData().then(()=>{
        if(data.role.transaction){
            if(data.state === 1){
                navigate('/manualEntry')
            }else if(data.state === 2){
                navigate('/manualZFBEntry')
            }else if(data.state === 3){
                navigate('/creditCard')
            }else if(data.state === 0){
                navigate('/collection/new')
            }
        }else{
            window.f7.dialog.alert('您暂未开通支付通道，目前无法操作',' ')
        }
    })
}
const roleRight=()=>{
    PermissionData().then(()=>{
        if(data.role.store){
            if(data.state === 1){
                navigate('/manualEntry')
            }else if(data.state === 2){
                navigate('/manualZFBEntry')
            }else if(data.state === 3){
                navigate('/creditCard')
            }else if(data.state === 0){
                navigate('/shopList/index')
            }
        }else{
            window.f7.dialog.alert('您暂未门店管理权限，目前无法操作',' ')
        }
    })
}

//交易数据获取
const TransactionData=async()=>{
    time.value = dayjs(date.value).format('YYYY-MM-DD');
    let res = await getTransactionData(time.value);
    data.value = res;
    let user:any = localStorage.getItem("user")
    data.nickname = JSON.parse(user)?.nickname;
}


//去活动详情
const toActivityDetail=()=>{
    ActivityShow.value = false;
    navigate('/redActivity/detail');
}

const ActivityShow = ref(false);
//活动红包弹窗
const ActivityRedPacketShow=async()=>{
    let res = await getActivityRedPacketShow()
    ActivityShow.value = res.show;
}

const RefundApproval=()=>{
    navigate('/RefundList/list')
}

onMounted(()=>{
    RegisterState()
    TransactionData()
    ActivityRedPacketShow();
})


// const closeOrder = makeLoader(api.closeOrder).withIndicator()

// function askCloseOrder(tradeNo: string) {
//     window.f7.dialog.confirm('','确定关闭此订单？', () => {
//         closeOrder.load(tradeNo).then(reloadOrderList)
//     }).open()
// }


</script>

<style scoped>
.bg{
    /* background-image: url(./assets/bg.svg); */
    /* background-size: cover; */
    background: linear-gradient(180deg, var(--f7-theme-color)  0%, var(--f7-md-inverse-primary) 100%);
}
.text-top{
    margin-top: 27px;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
}
.text-bottom{
    margin-top: 4px;
    margin-bottom: 47px;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
}
.dataBg{
    height: 40px;
    top: 192px;
    padding: 18px 12px 0px 0px;
    border-radius: 8px 8px 0px 0px;
    background-color: #FFFFFF;
}
.dateBg{
    position: absolute;
    right: 12px;
    top: 18px;
    border-radius: 4px;
    border: 0.4px solid #9B9B9B;
    border-left: 0.8px solid #9B9B9B;
    height: 26px;
}
.moneyColor{
    font-family: PingFang SC;
    font-size: 24px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--f7-theme-color);
    margin-top: 26px;
}
.moneyColor-1{
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #1E1E1E;
    margin-bottom: 16px;
}
.dealLeft{
    display: flex;
    width: 164px;
    height: 60px;
    padding: 8px 20px 8px 20px;
    border-radius: 8px;
    gap: 16px;
    background-color: var(--f7-md-surface-2);
    /* background-color: #FFF2F1; */
}
.dealLeft-1{
    display: flex;
    width: 32px;
    height: 32px;
    background-color: #FFFFFF;
    align-items: center;
    border-radius: 100px;
    margin-top: 6px;
}
.dealLeft-2{
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #1E1E1E;
}
.dealLeft-3{
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #1E1E1E;
}
.serve{
    width: 94%;
    margin: 0 auto;
    justify-content: space-between;
    padding-top: 20px;
    background: linear-gradient(180deg, #FFFFFF 0%, #F8F8F8 100%);
}
.warp {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    background-color: rgba(0, 0, 0, 0.4);
}
.rect {
    position: fixed;
    top: 192px;
	width: 246px;
	height: 174px;
	background-color: #fff;
    border-radius: 8px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.rect1 {
    position: fixed;
    top: 192px;
    width: 286px;
    height: 291px;
    border-radius: 8px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-image: url(./assets/bandan-bg.svg);
    background-size: cover;
}
.dealLeft-icon1 {
    color: var(--f7-theme-color);
    background-color: currentColor;
    mask-image: url(./assets/dealLeft.svg);
    width: 20px;
    height: 20px;
    margin: auto;
}
.dealRight-icon2 {
    color: var(--f7-theme-color);
    background-color: currentColor;
    mask-image: url(./assets/dealRight.svg);
    width: 20px;
    height: 20px;
    margin: auto;
}
</style>