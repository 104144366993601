import { defineStore } from 'pinia'
import { getOrderCount } from '../pages/Home/api'

export const useTodoStore = defineStore('todo', {
  // 相当于 vue 的 data
  state() {
    return {
      newAll:{
        totalCount:'',
        successCount:'',
        waitCount:''
      },
      markFlag:false,
      upDateFlag:false
    }
  },
  // 相当于 vue 的 computed
  getters: {},
  // 相当于 vue 的 methods
  actions: {
    async OrderCount(){ //角标获取
      let res = await getOrderCount()
      this.newAll = res
      return res
    },
    MyMarkFlag (){
      this.markFlag = true
      return this.markFlag
    },
    MyMarkFlagFalse (){
      this.markFlag = false
      return this.markFlag
    },
    tabsUpDateLeft(){
      this.upDateFlag = false
      return this.upDateFlag
    },
    tabsUpDateRight(){
      this.upDateFlag = true
      return this.upDateFlag
    },
  },
})