import Framework7 from 'framework7/lite';
import Framework7Vue, { f7ready } from 'framework7-vue';
import 'framework7/css';

Framework7.use(Framework7Vue)

import preloader from 'framework7/components/preloader'
import 'framework7/components/preloader/css'
Framework7.use(preloader)

import ptr from 'framework7/components/pull-to-refresh'
import 'framework7/components/pull-to-refresh/css'
Framework7.use(ptr)

import infinite from 'framework7/components/infinite-scroll'
import 'framework7/components/infinite-scroll/css'
Framework7.use(infinite)

import popup from 'framework7/components/popup'
import 'framework7/components/popup/css'
Framework7.use(popup)

import input from 'framework7/components/input'
import 'framework7/components/input/css'
Framework7.use(input)

import radio from 'framework7/components/radio'
import 'framework7/components/radio/css'
Framework7.use(radio)

import toggle from 'framework7/components/toggle'
import 'framework7/components/toggle/css'
Framework7.use(toggle)

import tooltip from 'framework7/components/tooltip'
import 'framework7/components/tooltip/css'
Framework7.use(tooltip)

import toast from 'framework7/components/toast'
import 'framework7/components/toast/css'
Framework7.use(toast)

import checkbox from 'framework7/components/checkbox'
import 'framework7/components/checkbox/css'
Framework7.use(checkbox)

import sheet from 'framework7/components/sheet'
import 'framework7/components/sheet/css'
Framework7.use(sheet)

import searchbar from 'framework7/components/searchbar'
import 'framework7/components/searchbar/css'
Framework7.use(searchbar)

import tabs from 'framework7/components/tabs'
import 'framework7/components/tabs/css'
Framework7.use(tabs)

import loginScreen from 'framework7/components/login-screen'
import 'framework7/components/login-screen/css'
Framework7.use(loginScreen)

import actions from 'framework7/components/actions'
import 'framework7/components/actions/css'
Framework7.use(actions)


import photoBrowser from 'framework7/components/photo-browser'
import 'framework7/components/photo-browser/css'
Framework7.use(photoBrowser)

import swiper from 'framework7/components/swiper'
import 'framework7/components/swiper/css'
Framework7.use(swiper)


import dialog from 'framework7/components/dialog'
import 'framework7/components/dialog/css'
import { Plugin } from 'vue';
Framework7.use(dialog)

declare global {
    interface Window {
        /**
         * 仅作为一种快捷调用方式、初始化完成前有可能为undefined, 要避免请使用f7ready获取f7实例
         */
        f7: Framework7 
    }
}

f7ready((f7) => {
    window.f7 = f7
})

const install: Plugin = function (app) {
    
}

export default install