import { FieldDesc } from "src/components/DynamicFields/DynamicFields.vue";
import { resultData , http } from "src/http";
import { Paged, PagedParams } from "src/Loader";
import { defineEnum } from "src/modules/defineEnum";


export function getOrderList(params: PagedParams & {
    state?: OrderState,
    time: string //yyyy-MM
    endTime: string
}) {
    return resultData.post<Paged<Order>>('/jhserver/order/app/v1/list', params)
}

export function closeOrder(tradeNo: string) {
    return resultData.post('/jhserver/order/app/v1/orderClose', { tradeNo })
}

export function getOrderDetail(tradeNo: string) {
    return resultData.post<OrderDetail>('/jhserver/order/app/v1/detail', { tradeNo })
}

/**
 * 金额预测
 */
export function compute(params: {
    "amount": string,
    "isFeeSeller": 0 | 1,
    "num": number,
    "payKey": string,
    "payeeKey": string,
    "tradeNo": string
}) {
    return resultData.post<{
        "amount": string,
        "num": number,
        "totalAmount": string
    }>('/jhserver/order/app/v1/compute', params)
}

export type QRCollectionParams = {
    "activityId"?: number,
    "amount"?: string,
    "customerName"?: string,
    "customerPhone"?: string,
    "imei"?: string,
    "isFeeSeller"?: 0 | 1 | '0' | '1',
    "num"?: string | number,
    "payKey"?: string,
    "payeeKey"?: string,
    "productName"?: string,
    "productSpec"?: string,
    "tradeNo"?: string,
    "bankCode"?:string,
    'provinceCode':any,
    'outCashierNo'?:string | number,
}

/**
 *  @return tradeNo 用于查询收款二维码
 * */
export function qrcollection(params: QRCollectionParams) {
    return resultData.post<{ tradeNo: string }>('/jhserver/order/app/v2/qr', params)
}
export function payDetail(tradeNo: string) {
    return resultData.post('/jhserver/order/app/v2/payDetail', { tradeNo })
}
export function refund(tradeNo: string) {
    return resultData.post('/jhserver/order/app/v1/refund', { tradeNo })
}
export function refreshQr(tradeNo: string) {
    return resultData.post('/jhserver/order/app/v2/refreshQr', { tradeNo })
}
export function getOrderCount() {
    return resultData.post('/jhserver/order/app/v2/getOrderCount')
}


//门店状态列表
export function getOrderFormStateList() {
    return resultData.post('/jhserver/order/app/v2/stateList')
}

//门店列表
export function getOrderFormList(params: PagedParams & {
    date: string //yyyy-MM
    state?: OrderState,
    id: number,
    storeNameOrTradeNo: string
}) {
    return resultData.post<Paged<Order>>('/jhserver/order/app/v2/merchantOrderList', params)
}

//银行列表
export function getBankList(params: any) {
    return resultData.post<any>('/jhserver/order/app/v2/getUserBankList', params);
}

//办单红包奖励
export function computeRedPacket(params: any) {
    return resultData.post<any>('/jhserver/marketingactivity/app/v1/computeRedPacket', params);
}

//申请权限
export function applyPermission(params: any) {
    return resultData.post<any>('/jhserver/permission/apply/app/v1/applyPermission', params);
}

//验证授权码
export function verifyAuthCode(params: any) {
    return http.json.post<any>('/jhserver/permission/apply/app/v1/verifyAuthCode', params);
}

//申请退款
export function refundApply(params: any) {
    return http.json.post<any>('/jhserver/refund/apply/app/v1/apply', params);
}

//撤销退款申请
export function refundApplyCancel(params: any) {
    return http.json.post<any>('/jhserver/refund/apply/app/v1/cancel', params);
}

//审核
export function refundApplyAudit(params: any) {
    return http.json.post<any>('/jhserver/refund/apply/app/v1/audit', params);
}

export type OrderDetail = {
    "disabledChange": boolean,
    "id": number,
    "bankCode"?: string,
    'provinceCode':string,
    "bankName"?: string,
    "orderInfo": [
        {
            "key": string,
            "keyColor": string,
            "value": string,
            "valueColor": string
        }
    ],
    "payInfo"?: [
        {
            "key": string,
            "keyColor": string,
            "value": string,
            "valueColor": string
        }
    ],
    "payeeInfo"?: PayeeInfo[],
    "showPayeeInfo": boolean,
    "showQrBtn": boolean,
    "showRefundBtn": boolean,
    "showScanBtn": boolean,
    "state": OrderState,
    "storeName": string,
    "amount": string,
    "tradeNo": string,
    "merchantNo": string,
    "showDialog": false,
    "reason": string,
    "requireOutCashierNo": boolean,
    "customerPhone"?:string,
    "outCashierNo"?:string,
}

export type PayeeInfo = {
    "choose": boolean,
    "key": string,
    "payInfo": PayInfo[],
    "value": string
}

export type PayInfo = {
    "choose": boolean,
    "insCal": boolean,
    "key": string,
    "numList"?: NumInfo[],
    "showQrBtn": boolean,
    "showScanBtn": boolean,
    "value": string
}

export type NumInfo = {
    "change": boolean,
    "choose": boolean,
    "condition": 0 | 1,
    "isFeeSeller": boolean,
    "key": string,
    "show": boolean,
    "value": string
}

export const OrderState = defineEnum({
    //0 待支付 1 交易成功 -1 交易关闭 2 退款中 3 已退款
    待支付: 0 as OrderState,
    交易成功: 1 as OrderState,
    交易关闭: -1 as OrderState,
    退款中: 2 as OrderState,
    已退款: 3 as OrderState
})

export type OrderState = -1 | 0 | 1 | 2 | 3;

export type Order = {
    "id": number,
    "orderInfo": FieldDesc[],
    "showCloseBtn": boolean,
    "showPayBtn": boolean,
    "state": OrderState,
    "title": string,
    "tradeNo": string
}
