import { Router } from "framework7/types"
import List from './活动列表/index.vue'
const Detail = () => import('./活动收款/index.vue')

const routes: Router.RouteParameters[] = [
    {
        path: '/offers/list',
        component: List
    },
    {
        path: '/offers/detail/:id',
        asyncComponent: Detail
    }
]

export default routes
