export function defineEnum<Cases extends Record<string, string | number>>(cases: Cases) {
    return new Proxy({
        ...cases
    }, {
        get(target, p) {
            if (Reflect.has(target, p)) {
                return Reflect.get(target, p)
            } else {
                for (const key in target) {
                    if (!Object.prototype.hasOwnProperty.call(cases, key)) {
                        continue;
                    }
                    const value = cases[key]
                    if (typeof value === 'symbol') {
                        if (p === value) {
                            return key
                        }
                    } else if (p === String(value)) {
                        return key;
                    }
                }
                return p;
            }
        }
    }) as typeof cases & {
        [key: string | number]: string
    }
}
