import { f7ready } from "framework7-vue";

//阻止浏览器返回，并且在返回时调用f7路由返回
export function init() {
    f7ready((f7) => {
        window.history.pushState(null, "", window.location.href);
        window.addEventListener("popstate", (e) => {
            window.history.pushState(null, "", window.location.href);
            //如果没有打开的dialog，就调用f7路由返回
            const router = f7.views.current.router
            if (dialogOpenedCount() === 0 && router && router.history.length > 1) {
                router.back();
            }
        });
    });
}

//监听f7 dialog:opened和closed事件，记录当前有多少个打开的dialog
export function dialogOpenedCount(): number {
    return count;
}
let count = 0;
f7ready((f7) => {
    f7.on("dialogOpen", () => count++);
    f7.on("dialogClosed", () => count--);
    f7.on("popoverOpen", () => count++);
    f7.on("popoverClosed", () => count--);
    f7.on("sheetOpen", () => count++);
    f7.on("sheetClosed", () => count--);
    f7.on("panelOpen", () => count++);
    f7.on("panelClosed", () => count--);
    f7.on("popupOpen", () => count++);
    f7.on("popupClosed", () => count--);
    f7.on("actionsOpen", () => count++);
    f7.on("actionsClosed", () => count--);
    f7.on("smartSelectOpen", () => count++)
    f7.on("smartSelectClosed", () => count--)
});

//判断是否在wkwebview中
export function isWKWebView(): boolean {
    return /iPhone/.test(navigator.userAgent) && /WebKit/.test(navigator.userAgent);
}

//在wkwebview中不执行init()
if (!isWKWebView()) {
    init();
}


