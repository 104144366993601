<template>
    <div class="round-card">
        <slot></slot>
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped>
    .round-card {
        background-color: var(--content-bg-color);
        border-radius: 8px;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.03);
    }
</style>