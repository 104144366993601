<template>
    <f7Page :class="{'no-toolbar': !tabRoot}" v-bind="$attrs" :page-content="false" @page:beforein="pageBeforeIn" @page:afterin="pageAfterIn"
            @page:beforeout="pageBeforeOut" @page:afterout="pageAfterOut" @page:tabhide="pageBeforeOut(); pageAfterOut()" @page:tabshow="pageBeforeIn();pageAfterIn()">
        <slot name="navbar">
            <f7Navbar :transparent="transparent" :large="large" :hidden="isNavBarHidden">
                <f7NavLeft>
                    <f7Link class="back-link" @click="routerBackIfNeeds"><img class="w-6 h-6" src="./assets/back.svg" alt=""></f7Link>
                    <slot name="nav-left"></slot>
                </f7NavLeft>
                <f7NavTitle><slot name="nav-title">{{title}}</slot></f7NavTitle>
                <f7NavTitleLarge v-if="large && !isNavBarHidden"><slot name="nav-title-large">{{title}}</slot></f7NavTitleLarge>
                <f7NavRight><slot name="nav-right"></slot></f7NavRight>
            </f7Navbar>
        </slot>
        <f7PageContent :class="{ 'is-navbar-hidden': isNavBarHidden }">
            <div class="page-content-inner">
                <slot></slot>
            </div>
        </f7PageContent>
    </f7Page>
</template>

<script setup lang="ts">
import { computed } from '@vue/reactivity';
import { f7Page, f7PageContent, f7Navbar, f7NavLeft, f7NavTitle, f7NavRight, f7NavTitleLarge, f7Link } from 'framework7-vue';
import { Router } from 'framework7/types';
import { getCurrentInstance } from 'vue';
import { inWeixin } from 'src/modules/EnvironmentDetect';
import { storeToRefs } from 'pinia';
import {useTodoStore} from '../../stores/mark'

const todoStore  = useTodoStore()
const cornerMark =async() =>{
    await todoStore.OrderCount()
}
const emit = defineEmits<{
    (event: 'willAppear'): void
    (event: 'didAppear'): void
    (event: 'willDisappear'): void
    (event: 'didDisappear'): void
}>()

const props = withDefaults(defineProps<{
    f7router: Router.Router
    f7route: Router.Route
    title?: string
    tabRoot?: boolean
    navbar?: boolean
    transparent?: boolean
    large?: boolean
    back?: string | ((router: Router.Router) => void)
    noHideNavbar?: boolean // 用于在微信中，不隐藏navbar
    routeAddress?: string
    OrderCount?: boolean | string //角标
}>(), {
    title: '',
    navbar: false
})
const isTab = (() => {
    let cur = getCurrentInstance()
    while (cur) {
        if (cur.type.name === 'f7-tabs') {
            return true
        }
        cur = cur.parent
    }
    return false
})()

const isInWeixin = inWeixin()

// const isNavBarHidden = computed(() => !props.navbar || (isInWeixin && !props.transparent))

const isNavBarHidden = computed(() => {
    if(isInWeixin){
        return false
    }else{
        let flag = !props.navbar || (isInWeixin && !props.transparent)
        return flag
    }
})

function routerBackIfNeeds() {
    if(props.routeAddress){
        navigate(props.routeAddress)
    }else{
        if (props.back) {
            if (typeof props.back === 'string') {
                props.f7router.back(props.back, { transition: 'f7-parallax' })
            } else {
                props.back(props.f7router)
            }
        } else {
            props.f7router.back()
        }
    }
    if(props.OrderCount){
        setTimeout(() => {
            cornerMark()
        }, 500);
    }
}

const navigate = (url:string) => {
    props.f7router.navigate(url, { transition: 'f7-parallax' })
}


function pageBeforeIn() {
    emit('willAppear')
    updateHtmlTitle()
}
function pageAfterIn() {
    emit('didAppear')
}
function pageBeforeOut() {
    emit('willDisappear')
}
function pageAfterOut() {
    emit('didDisappear')
}

function updateHtmlTitle() {
    if(isInWeixin){
        document.head.querySelector('title')!.textContent = '京聚汇'
    }else{
        document.head.querySelector('title')!.textContent = props.title || '京聚汇'
    }
}


</script>

<style global>
.page-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
</style>

<style scoped>

.page-content.is-navbar-hidden {
    --f7-navbar-height: 0px;
    --f7-page-navbar-offset: calc(var(--f7-navbar-height) + var(--f7-safe-area-top));
}
.page-content-inner {
    position: relative;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
}

:deep(.ptr-preloader .preloader-inner),
:deep(.infinite-scroll-preloader .preloader-inner) {
    animation-timing-function: linear;
}

:global(.navbars>.navbar:first-child .back-link) {
    visibility: hidden;
}
</style>
