import { Loader } from "./Loader";

export type AsyncFunc<P extends any[] = any,R = any> = (...args: P) => Promise<R>
export type AsyncValue<A> = A extends AsyncFunc<any[], infer Value> ? Value : never

export class FuncLoader<Value, Params extends any[]> extends Loader<Value, Params> {
    
    constructor(public readonly load: (...args: Params) => Promise<Value>) {
        super();
    }

    static init<Func extends AsyncFunc>(func: Func): Loader<AsyncValue<Func>, Parameters<Func>> {
        return new FuncLoader<AsyncValue<Func>, Parameters<Func>>(func)
    }
}