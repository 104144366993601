import { FuncLoader } from "../FuncLoader"
import { Loader } from "../Loader"

declare module "../Loader" {
    interface Loader<Value, Params extends any[]> {
        retry(retryTimes: number): Loader<Value, Params>
    }
}

Loader.prototype.retry = function (retryTimes) {
    return new FuncLoader(async (...args) => {
        let error: any
        for (let i = 0; i < retryTimes; i++) {
            try {
                return await this.load(...args)
            } catch (e: any) {
                error = e
            }
        }
        throw error
    })
}
