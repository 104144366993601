<template>
  <div class="round-card">
    <div class="round-card-header">
      <span class="title">{{ qrcodeUrl.value?.title || title }}</span>
    </div>
    <div class="round-card-content">
      <div class="tips">
        <h4>活动介绍</h4>
        <p v-for="item in qrcodeUrl.value?.steps">{{ item }}</p>
        <!-- <div v-if="!qrcodeUrl.value?.steps">
          <div v-if="title == '白条福利活动码'">
            <p>第一步：请输入交易金额</p>
            <p>第二步：生成活动二维码</p>
            <p>第三步：用户使用京东/微信扫码参与提额/免息活动</p>
            <p>第四步：点击继续收款，展示收款码</p>
            <p>第五步：用户扫码付款</p>
          </div>
        </div> -->
      </div>
      <div class="grid justify-items-center">
        <div class="flex my-5" v-if="readonly">
          <span class="text-2xl text-[#DB3022] mr-3">￥{{ props.params?.amount }}</span>
        </div>
        <template v-else>
          <div class="flex my-5" v-if="!data.isEditing">
            <span class="text-2xl text-[#DB3022] mr-3">￥{{ props.params?.amount }}</span>
            <img src="./assets/input.svg" alt="" @click="openInput">
          </div>
          <div class="input-bg my-[14px]" v-else>
            <span class="symbol">￥</span>
            <input class="input self-stretch flex-1 w-0" type="text" v-model="data.editingAmount">
            <ActionButton class="w-[44px] h-[22px] btn-mini" style="font-size: 10px" round fill :action="define">确定
            </ActionButton>
          </div>
        </template>
        <div class="flex border rounded-lg border-solid border-[#DB3022] p-1 w-[166px] h-[166px]">
          <RedEnvelopeCode v-if="showQRCode" :url="qrcodeUrl.value?.url" :urlType="qrcodeUrl.value?.urlType" />
        </div>
        <p>二维码有效期30秒</p>
      </div>
      <div class="grid justify-items-center mb-0.5">
        <ActionButton class="w-[160px]" round fill @click="emit('continue')">继续收款</ActionButton>
      </div>
    </div>

  </div>
</template>

<script setup lang="ts">
import ActionButton from 'src/components/Inputs/ActionButton.vue';
import { makeLoader } from 'src/Loader';
import { reactive, watch } from "vue";
import RedEnvelopeCode from '../RedEnvelopeCode.vue';
import * as api from '../../api'
const props = defineProps<{
  title: string,
  params?: api.RedPacketCodeParams
  readonly?: boolean,
  showQRCode?: boolean //外部控制是否显示二维码，在某些动画中，二维码会影响动画流畅度，所以需要外部控制
}>()

const emit = defineEmits<{
  (e: 'update:amount', amount: string): void
  (e: 'continue'): void
}>()

const data = reactive({
  isEditing: false,
  editingAmount: props.params?.amount || ''
})

watch(() => props.params, (newParams) => {
  data.editingAmount = newParams?.amount || ''
  newParams && qrcodeUrl.load(newParams)
})

function openInput() {
  data.isEditing = true
}

const qrcodeUrl = makeLoader(api.redPacketCodeUrl)
  .showError()
  .onError(() => { qrcodeUrl.reset() })
  .reactive()

if (props.params) {
  qrcodeUrl.load(props.params)
}

function define() {
  data.isEditing = false
  emit('update:amount', data.editingAmount)
}

</script>

<style scoped>
.round-card {
  background-color: var(--content-bg-color);
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.03);
}

.round-card-header {
  position: relative;
  height: 57px;
  display: flex;
  align-items: center;
}

.round-card-header::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 18px;
  width: 7px;
  height: 20px;
  background-color: var(--f7-theme-color);
  border-radius: 0px 3px 3px 0px;
}

.title {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  color: #222222;
  margin-left: 16px;
  margin-right: auto;
}

.round-card-content {
  background-color: #FFFFFF;
  padding: 0 16px 16px 16px;
  border-bottom: 1px solid #F2F2F2;
}

.tips {
  border-bottom: 1px solid #F2F2F2;
  padding-bottom: 12px;
}

.tips h4 {
  font-family: 'PingFang SC';
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

.tips p {
  font-family: 'PingFang SC';
  font-size: 11px;
  font-weight: 400;
  line-height: 20px;
  margin: 2px 0;
}

.input-bg {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 10px;
  gap: 8px;
  width: 209px;
  height: 40px;
  background: #F7F7F7;
  border-radius: 8px;
}

.btn-mini {
  position: relative;
  z-index: 99;
}

.symbol {
  position: relative;
  top: 0;
  font-size: 24px;
  z-index: 9;
}

.input {
  font-size: 24px;
  font-weight: 400;
}

.action-button {
  flex: none;
}

.action-button:not(.button-fill) {
  --f7-button-bg-color: var(--content-bg-color);
  --f7-button-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.03);
}

.action-button.button-fill {
  --f7-button-box-shadow: 0px 4px 8px 0px rgba(211, 38, 38, 0.25);
}
</style>
