import {resultData} from "src/http"
import { Result } from "src/Loader"
import { Paged, PagedParams } from "src/Loader";
import {defineEnum} from "../../modules/defineEnum";

type ApiName = {
    name: string
}

export function apiName(foo: string) {
    return resultData.get('/jhserver/user/app/v1/getAppUrl?a=1', {})
}

export function getActivityList(params: PagedParams & {
    limit: number,
    lastId: number
}) {
    return resultData.post<Paged<Activity>>('/jhserver/activity/app/v2/list', params)
}

export function getActivityDetail(id: number) {
    return resultData.post<ActivityDetail>('/jhserver/activity/app/v1/detail', {id})
}

export const ActivityType = defineEnum({
    // 活动类型1-京东贴息 2-商户贴息 3-联合贴息
    京东贴息: 1 as ActivityType,
    商户贴息: 2 as ActivityType,
    联合贴息: 3 as ActivityType
})

export type ActivityType =  1 | 2 | 3;

export type Activity = {
    "id": number,
    "time": string,
    "num": string,
    "type": ActivityType,
    "title": string,
    "image": string
}
export type ActivityDetail = {
    "id": number,
    "storeName": string,
    "amount": string,
    "tradeNo": string,
    "payKey": string,
    "payeeKey": string,
    "num": number,
    "merchantNo": string,
    "discountChannel":boolean
}