import { Router } from "framework7/types"
import Index from './index.vue'
const Step1 = () => import('./步骤/Step1.vue')
const Step2 = () => import('./步骤/Step2.vue')
const Step3 = () => import('./步骤/Step3.vue')
const Store = () => import('./步骤/Store.vue')
const picture = () => import('./步骤/picture.vue')
const home = () => import('../Home/index.vue')
const routes: Router.RouteParameters[] = [
    {
        path: '/manualZFBEntry',
        component: Index
    },
    {
        name:'step1ZFB',
        path: '/manualZFBEntry/step1',
        asyncComponent: Step1
    },
    {
        path: '/manualZFBEntry/step2',
        asyncComponent: Step2
    },
    {
        path: '/manualZFBEntry/step3',
        asyncComponent: Step3
    },
    {
        path: '/manualZFBEntry/Store',
        asyncComponent: Store
    },
    {
        path: '/manualZFBEntry/picture',
        asyncComponent: picture
    },
    {
        path: '/home',
        asyncComponent: home
    },
]

export default routes
