<template>
     <!-- @will-appear="loadAll" -->
    <Page v-bind="$props" title="订单列表" navbar tab-root>
        <template #nav-right>
            <ExitMenuLink/>
        </template>
        <div v-if="todoList.markFlag._object.markFlag">
            <div v-show="beginning()"></div>
        </div>
        <f7Toolbar tabbar>
            <f7-link tab-link="#order-1" tab-link-active>
                <div style="display: flex;" @click="cornerMark(),left()">
                    待收款订单({{list1?.total ?? '...'}})
                    <div v-if="todoList.newAll._object.newAll.waitCount != ''" class="badge1">
                        <span>{{ todoList.newAll._object.newAll.waitCount > 99 ? 99 : todoList.newAll._object.newAll.waitCount }}</span>
                    </div>
                </div>
            </f7-link>
            <f7-link tab-link="#order-2">
                <div style="display: flex;" @click="cornerMark(),right()">
                    全部订单
                    <div v-if="todoList.newAll._object.newAll.successCount != ''" class="badge1">
                        <span>{{ todoList.newAll._object.newAll.successCount > 99 ? 99 : todoList.newAll._object.newAll.successCount }}</span>
                    </div>
                </div>
            </f7-link>
        </f7Toolbar>
        <f7Tabs class="flex-auto" animated>
            <f7Tab id="order-1" class="h-full relative" tab-active @tab:show="list1?.load()">
                <TodoOrderList class="absolute h-full w-full" ref="list1"></TodoOrderList>
            </f7Tab>
            <f7Tab id="order-2" class="h-full relative" @tab:show="list2?.load()">
                <OrderList class="absolute h-full w-full" ref="list2"></OrderList>
            </f7Tab>
        </f7Tabs>
    </Page>
</template>

<script setup lang="ts">
import { f7Link, f7Tab, f7Tabs, f7Toolbar } from 'framework7-vue';
import { Router } from 'framework7/types';
import Page from 'src/components/Pages/Page.vue';
import { onMounted, ref,onActivated } from 'vue';
import TodoOrderList from './TodoOrderList.vue';
import OrderList from './OrderList.vue'
import ExitMenuLink from 'src/pages/登录/登录/ExitMenuLink.vue';
import { storeToRefs } from 'pinia';
import {useTodoStore} from '../../../stores/mark'
const props = defineProps<{
    f7router: Router.Router
    f7route: Router.Route
}>();

const todoStore  = useTodoStore()
const todoList:any = storeToRefs(todoStore)

const list1 = ref<InstanceType<typeof TodoOrderList>>()
const list2 = ref<InstanceType<typeof OrderList>>()
onMounted(() => {
    // list1.value?.load()
    // list2.value?.load()
})
function loadAll() {
    list1.value?.load()
    list2.value?.load()
}
const cornerMark =async() =>{
    await todoStore.OrderCount()
}

const beginning =()=>{
    return setTimeout(()=>{
        if(todoList.upDateFlag._object.upDateFlag){
            list2.value?.load()
        }else{
            list1.value?.load()
        }
    },0)
}
const left = ()=>{
    list1.value?.load()
    todoStore.tabsUpDateLeft()
}
const right = ()=>{
    list2.value?.load()
    todoStore.tabsUpDateRight()
}
</script>

<style scoped>
.toolbar {
    height: 44px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.03);
}

.toolbar .link {
    font-size: 16px;
    font-weight: 500;
}
.badge1{
    /* position:absolute; */
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: center;
    height: 16px;
    line-height: 17px;
    border-radius: 50%;
    color: #FFFFFF;
    background-color: #E34D59;
    width: 16px;
}
</style>