<template>
    <Page v-bind="$props" title="自主进件" navbar>
        <BaitiaoStatus :inviteCode="login.code()" @navigate="navigate" @logout="logout"/>
    </Page>
</template>

<script setup lang="ts">
import { Router } from 'framework7/types';
import { type } from 'os';
import Page from 'src/components/Pages/Page.vue';
import { defineProps, reactive } from 'vue';
import BaitiaoStatus from './components/BaitiaoStatus.vue';
import { sharedLogin } from 'src/Controllers/LoginConrtoller';
const login = sharedLogin

const props = defineProps<{
    f7router: Router.Router
    f7route: Router.Route
}>();

const data = reactive({});

type NavigateProps = {
  "name":'',
  "path": "",
  "query": {}
}
const navigate = (value: NavigateProps) => {
    props.f7router.navigate({
        name: value.name,
        path: value.path,
        query: value.query
    });
}

const logout = ()=>{
    login.setLogin(null)
}

//注册


</script>

<style scoped>

</style>
