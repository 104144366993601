<template>
    <RoundDesc class="m-4" :name="label ?? '验证码'" divide >
        <input class="self-stretch flex-1 w-0" type="text" :value="modelValue" @input="emit('update:modelValue', $event.target.value)" :placeholder="placeholder ?? '请输入验证码'" />
        <f7Link class="flex-none" @click="getSms">{{ leftTime.showName }}</f7Link>
    </RoundDesc>
</template>

<script setup lang="ts">
import { f7Link } from 'framework7-vue';
import RoundDesc from 'src/components/RoundControls/RoundDesc.vue';
import { makeLoader } from 'src/Loader';
import * as api from "../../api";
import * as myApi from "../../../我的/api";
import { computed ,reactive} from 'vue';
const props = defineProps<{
    label?: string
    placeholder?: string
    modelValue: string
    fetchPhoneSms: () => Promise<Boolean>
}>();

const emit = defineEmits<{
    (event: 'update:modelValue', value: string): void
}>();

const showName = function() {
    console.log(leftTime.showName)
    if(leftTime.value<=0) {
        return '获取验证码'
    } else {
        return leftTime.value+'s'
    }
}

const leftTime = reactive({
    value:0,
    showName: '获取验证码'
})

function getSms() {
    if(leftTime.value>0) {
        return
    }
    props.fetchPhoneSms().then((success) => {
        if(success) {
            leftTime.value = 60
            let interval = setInterval(() => {
                leftTime.value--
                console.log(leftTime.value)
                if(leftTime.value<=0) {
                    leftTime.value = 0
                    clearInterval(interval)
                }
                leftTime.showName = showName()
            }, 1000);
        }
    })
}

</script>

<style scoped>

</style>
