import { FuncLoader } from "../FuncLoader"
import { Loader } from "../Loader"

declare module "../Loader" {
    interface Loader<Value, Params extends any[]> {
        mapValue<NewValue>(map: (value: Value) => NewValue | PromiseLike<NewValue>): Loader<NewValue, Params>
        mapParams<NewParams extends any[]>(mapParams: (...args: NewParams) => Params | PromiseLike<Params>): Loader<Value, NewParams>
    }
}

Loader.prototype.mapValue = function (map) {
    return new FuncLoader(async (...args) => map(await this.load(...args)))
}

Loader.prototype.mapParams = function (map) {
    return new FuncLoader(async (...args) => this.load(...await map(...args)))
}