import qs from 'qs';

declare global {
    interface Location {
        readonly setHrefSilent: (url: string) => void
        get query(): qs.ParsedQs
    }
}
const originEncodeURIComponent = window.encodeURIComponent
window.encodeURIComponent = (uriComponent: string | number | boolean): string => {
    return originEncodeURIComponent(uriComponent).replace(/[!'()*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16).toUpperCase();
    });
}

Object.defineProperties(window.location, {
    setHrefSilent: {
        writable: false,
        value: function (url: string) {
            if (!!(window.history && history.pushState)) {
                // 支持History API
                history.replaceState(null, null, url)
            } else {
                console.warn("不支持设置历史记录");
            }
        }
    },
    query: {
        get() {
            return qs.parse(window.location.search.replace(/^\?/, ''));
        }
    }
})

