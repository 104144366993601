import { Loader } from "../Loader";

declare module "../Loader" {
    interface Loader<Value, Params extends any[]> {
        showError(): Loader<Value, Params>
        showSuccess(message: string): Loader<Value, Params>
    }
}

import correct from 'src/components/RoundControls/assets/correct.svg'
import wrong from 'src/components/RoundControls/assets/wrong.svg'

Loader.prototype.showError = function () {
    return this.onError(error => {
        window.f7.toast.show({
            text: error.message,
            position: 'center',
            destroyOnClose: true,
            icon: `<img src="${wrong}" alt="" style="margin: auto">`,
            closeTimeout: 3000
        })
    })
}

Loader.prototype.showSuccess = function (message) {
    return this.onLoad(() => {
        window.f7.toast.show({
            text: message,
            position: 'center',
            destroyOnClose: true,
            icon: `<img src="${correct}" alt="" style="margin: auto">`,
            closeTimeout: 2000
        })
    })
}