<template>
    <div>
        <div class="box" v-if="indexData.value">
            <div class="box-1">
                <div class="content">
                    <div class="logo">
                        <img src="./assets/logo1@3x.png" style="width: 56px"/>
                    </div>
                    <div class="text-center" style="padding-top: 12px;">
                        <span style="color: #FFFFFF;font-size: 14px!important" >欢迎您使用京聚汇</span>
                    </div>
                    <div class="content-list">
                        <div class="tab1"></div>
                        <div class="tab1-top" style="border-radius: 10px;overflow: hidden;padding-bottom: 12px;border-bottom: #E6E6E6 1px dashed;">
                            <p style="font-weight: bolder;padding-top: 10px;font-size: 18px;color: var(--f7-theme-color);">{{indexData.value?.title}}</p>
                            <p style="color: #979797;padding: 0 30px 10px 30px">{{indexData.value?.content}}</p>
                        </div>
                        <div style="border-radius: 10px;overflow: hidden;background: white;padding-bottom: 12px;"> 
                            <div class="content-info" style="margin-top: 25px;padding-top: 10px;padding-left: 12px;">
                                <div class="logoBg">
                                    <img style="width: 40px;height: 40px;margin: 8px auto;" src="../步骤/assets/creditCard.svg" class="content-img" />
                                </div>
                                <div class="content-middle">
                                    <span style="font-weight: 600;margin-left: 15px;font-size: 15px;line-height: 20px;">信用卡分期</span>
                                    <div style="color: #989898; align-items: left; margin-left: 15px;width:100%;text-align:left">
                                        <div class="content-span" style="display:inline-block" :class="[ indexData.value.state == -1 ? 'content-span redBorder' : 'content-span']" v-if="indexData.value && indexData.value.leftBtn"><span >{{indexData.value?.leftBtn}}</span></div>
                                        <div class="content-span" style="display:inline-block" v-if="indexData.value && indexData.value.rightBtn"><span >{{indexData.value?.rightBtn}}</span></div>
                                    
                                    </div>
                                </div>
                            </div>
                            <ActionButton fill type="info" class="mt-4 mx-4" style="margin-top: 24px;" v-if="indexData.value?.btnTop" @click="navigate">{{indexData.value?.btnTop}}</ActionButton>
                            <ActionButton fill type="info" class="mt-4 mx-4" style="margin-top: 24px;" v-if="indexData.value?.btnDown" @click="navigate">{{indexData.value?.btnDown}}</ActionButton>
                            <p class="blackText">{{indexData.value?.bottomStr}}</p>
                            <div v-if="!indexData.value?.bottomStr" style="height: 16px;width: 100%;"></div>
                        </div>
                        
                        <p style="text-decoration-line: underline;color: #FFFFFF;font-size: 14px;line-height: 26px;" @click="toLogin">返回登录页面</p>
                    </div>
                </div>
            </div>
    </div>
    </div>
    

</template>

<script setup lang="ts">
import { Router } from 'framework7/types';
import { defineProps, reactive ,defineEmits,ref, onMounted} from 'vue';
import ActionButton from 'src/components/Inputs/ActionButton.vue';
import { lianlianh5IndexPage } from './api'
import { makeLoader } from 'src/Loader';
import {queryRegisterState} from "../api";
const props = defineProps<{
    inviteCode:string | null
}>();


const data = reactive({
    indexData:{
        title:'京东白条',
        content:'您还未进行实名认证\n请提交实名认证资料',
        leftBtn:'未实名',
        rightBtn:'产品未开通',
        show:true,
        topType:0,
        downType:0,
        bottomStr:'申请审核中，请稍后查看',
    },
    registerState:{
        content:'您还未注册，请注册成功后再进行操作',
        url:'',
        state:0,
        btn:'去注册'
    }
});

const toFlag = ref(true)

const emit = defineEmits(['navigate','logout']);

//跳转
const navigate = () => {
    let data:any = indexData.value
    let type = data?.state
    switch (type) {
        case 0://
            emit('navigate',{
                path:'/creditCard/StoreNew',
                query:{}
            });
            break;
        case 4://待签约
            emit('navigate',{
                path:'/creditCard/StoreNew',
                query:{}
            });
            break;
        case -1://驳回
            emit('navigate',{
                path:'/creditCard/StoreNew',
                query:{}
            });
            break;
        default:
            break;
    }
}
const indexData = makeLoader(lianlianh5IndexPage)
                .withIndicator()
                .mapParams(() => [{
                    inviteCode:props.inviteCode
                }])
                .showError()
                .mapError(() => {
                    toLogin()
                    toFlag.value = data.indexData.show
                    return data.indexData
                })
                .reactive()
                .immediatelyLoad()

const toLogin = () => {
    emit('logout');
}

const home = () =>{
    setTimeout(()=>{
        if(indexData.value?.show == false){
            emit('navigate',{
                path:'/home',
                query:{}
            });
        }
    },500)
    
}

onMounted(async()=>{
        // home()
})


</script>

<style scoped>
    .box {
        background-color:var(--f7-theme-color);
        background-size: 100% 100%;
        height: 100%;
        width: 100%;
        position: fixed;
        overflow-y: scroll;
    }
    .box .box-1{
        width: 100%;
        height: 100%;
        background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0.4));
        overflow: hidden;
    }
    .content {
        padding: 25px;
    }

    .logo {
        margin-top: 40px;
        text-align: center;
        display: flex;
        justify-content: center;

    }

    .content-list {
        margin: 30px 0 0 0;
        padding-bottom: 30px;
        border-radius: 10px;
        /* background: white; */
        height: 100%;
        position: relative;
        text-align: center;
    }
    .content-img{
        width: 27%;
    }
    .content-img1{
        width: 91.47%;
        margin: 16px auto;
    }
    .content-info{
        /* background-color: #f5f5f5; */
        height: 100%;
        margin: auto 35px;
        padding: 15px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        border: 1px solid #DCDFE6;
        border-radius: 6px;
    }
    .content-middle{
        /* margin-left: 15px; */
        width: 73%;
        display:flex;
        flex-direction: column;
        align-items: self-start;
        /* justify-content: left; */
    }
    .content-span{
        border: 1px solid #cccccc;
        padding: 2px 6px 2px 6px;
        font-size: 11px;
        margin-top: 8px;
        border-radius: 2px;
        margin-right: 10px;
    }
    .tab1{
        width: 104%;
        height: 20px;
        border-radius: 12px;
        background-color: var(--f7-theme-color);
        position: absolute;
        top: -10px;
        left: -2%;
        /* opacity: 0.5; */
    }
    .tab1-top{
        background: linear-gradient(180deg, var(--f7-md-inverse-primary) 0%, #FFFFFF 14.68%);
        position: relative;
    }
    .redText{
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0px;
        text-align: center;
        color: var(--f7-theme-color);
        text-decoration:underline
    }
    .blackText{
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: center;
        color: #1E1E1E;
    }
    .upDown{
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background: #0000004D;
    }
    .upDown1{
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 80vh;
        background-color: #FFFFFF;
        border-radius: 20px 20px 0 0;
        text-align: center;
    }
    .upTitle{
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 800;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: center;
        color: #1E1E1E;
        margin-top: 20px;
    }
    .logoBg{
        width: 56px;
        height: 56px;
        top: 405px;
        left: 75px;
        border-radius: 4px;
        background-color: #F1F1F1;
    }
    .redBorder{
        border-color: var(--f7-theme-color);
        color: var(--f7-theme-color);
    }
</style>
